import { useEffect } from 'react'
import { BarDatum, BarItem, BarItemProps } from '@nivo/bar'
import { useSpring } from '@react-spring/web'

const BAR_MIN_HEIGHT = 4

const CustomBarComponent = <RawDatum extends BarDatum>(props: BarItemProps<RawDatum>) => {
  const {
    bar: { data, ...bar },
    style,
  } = props

  const w = Math.min(bar.width)
  const h = Math.max(bar.height, BAR_MIN_HEIGHT)
  const x = bar.x + bar.width / 2 - w / 2

  const [spring, setSpring] = useSpring(() => ({
    width: w,
    height: h,
  }))

  useEffect(() => {
    setSpring({ width: w, height: h })
  }, [w, setSpring, x, bar])

  return (
    <BarItem
      {...props}
      bar={{
        ...bar, data, x, width: w, height: h,
      }}
      style={{ ...style, ...spring }}
    />
  )
}

export default CustomBarComponent
