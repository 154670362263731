import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'
import { MAP_LAYER_SOURCE, formatLayerUrl } from 'services/map'
import TrackLayerPaint from './TrackLayerPaint'

export default function TrackLayer(): ReactElement {
  const mode = 'full_rgi_line_geo'
  const params = 'type_habillage_id_libelle=Statut&valeur_libelle__in=Exploitée,Transférée en voie de service'
  return (
    <Source
      type="vector"
      url={formatLayerUrl(MAP_LAYER_SOURCE.track, mode, params)}
    >
      <Layer
        {...TrackLayerPaint.trackLayer}
        source-layer={MAP_LAYER_SOURCE.track}
      />

      <Layer
        {...TrackLayerPaint.trackNameLayer}
        source-layer={MAP_LAYER_SOURCE.track}
      />

      <Layer
        {...TrackLayerPaint.trackNumberLayer}
        source-layer={MAP_LAYER_SOURCE.track}
      />
    </Source>
  )
}
