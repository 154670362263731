import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'reducers/store'
import { Zone } from './types'

const getZoneById = (id: string) => createSelector(
  (state: RootState) => state.zones.list,
  (list: Zone[]) => list.find(zone => zone.id === id),
)

export default {
  getZoneById,
}
