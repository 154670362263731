import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'reducers/store'
import { Board } from './types'

const getBoardById = (id: string) => createSelector(
  (state: RootState) => state.boards.list,
  (list: Board[]) => list.find(board => board.id === id),
)

const getOpenedBoards = (zoneId: string) => createSelector(
  (state: RootState) => state.boards.list,
  (state: RootState) => state.boards.opened,
  (list: Board[], opened: {[key: string]: string[]}) => list
    .filter(board => opened[zoneId] && opened[zoneId].includes(board.id)),
)

export default {
  getBoardById,
  getOpenedBoards,
}
