import { useEffect, useState } from 'react'
import { Popup } from 'react-map-gl'

import './Popup.scss'

type Props = {
  title: string
  sections: {
    [key: string]: string
  }[]
  coordinate: [number, number]
  onClose: () => void
}

export const PRPopup = ({
  title, sections, coordinate, onClose,
}: Props) => {
  const [displaySection, setDisplaySection] = useState(sections)

  useEffect(() => {
    setDisplaySection(sections)
  }, [sections])

  return (
    <Popup
      longitude={coordinate[0]}
      latitude={coordinate[1]}
      closeButton
      closeOnClick={false}
      captureScroll
      onClose={onClose}
      className="map-widget-popup"
    >
      <div className="content">
        <h3>{title}</h3>
        <div className="wrapper">
          {displaySection.map((section, i) => (section && Object.keys(section).length > 0) && (
          // eslint-disable-next-line react/no-array-index-key
          <div className="section" key={`section-${i}`}>
            {Object.keys(section).map(key => (section[key] !== undefined ? (
              <div className="row" key={key}>
                {/* eslint-disable-next-line react/no-danger */}
                <span className="label" dangerouslySetInnerHTML={{ __html: key }} />
                {section[key]?.toString() && (
                <span className="value">{` : ${section[key]?.toString()}`}</span>
                )}
              </div>
            ) : null))}
          </div>
          ))}
        </div>
      </div>
    </Popup>
  )
}

export default PRPopup
