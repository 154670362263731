import { ReactElement } from 'react'
import { Star, StarBorder } from '@mui/icons-material'

import './FavoriteIcon.scss'

interface Props {
  isFavorite: boolean;
  handleClick: () => void;
}

const FavoriteIcon = ({ isFavorite, handleClick }: Props): ReactElement => (
  <div className={`favorite-icon ${isFavorite ? 'selected' : ''}`}>
    <button type="button" onClick={handleClick}>
      {isFavorite ? <Star /> : <StarBorder />}
    </button>
  </div>
)

export default FavoriteIcon
