/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import moment, { Moment } from 'moment'
import { FormLabel } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import RemoveIcon from '@mui/icons-material/Remove'
import terms from 'common/terms'
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg'
import './customMultitimeRange.scss'

export enum PresetRange {
  FULL = '06:30:00-09:30:59',
  OFF_PEAK_MORNING = '00:00:00-06:29:59',
  OFF_PEAK_EVENING = '09:31:00-23:59:59',
}

type Props = {
  selected: string[]
  handleChange: (value: string | string[], toReplace?: string) => void
}

const CustomMultitimeRange = ({ selected, handleChange }: Props) => {
  const selectedWithoutPresets = selected?.filter(
    range => ![PresetRange.FULL, PresetRange.OFF_PEAK_EVENING, PresetRange.OFF_PEAK_MORNING].includes(
      range as PresetRange,
    ),
  ) || []
  const [manualMode, setManualMode] = useState<boolean>(selectedWithoutPresets.length > 0)
  const [timeRanges, setTimeRanges] = useState<{start: string, end: string, id: string}[]>(
    selectedWithoutPresets.map(range => {
      const [start, end] = range.split('-')
      return { start, end, id: uuidv4() }
    }),
  )

  const addNewTimeRange = () => {
    setTimeRanges([...timeRanges, { start: null, end: null, id: uuidv4() }])
  }

  const removeTimeRange = (id: string) => () => {
    const timeRange = timeRanges.find(tr => tr.id === id)
    const timeRangesFiltered = timeRanges.filter(tr => tr.id !== id)
    if (timeRangesFiltered.length === 0) setManualMode(false)
    if (timeRange?.start && timeRange?.end) handleChange(`${timeRange.start}-${timeRange.end}`)
    setTimeRanges(timeRangesFiltered)
  }

  useEffect(() => {
    if (manualMode && timeRanges?.length === 0) addNewTimeRange()
  }, [manualMode])

  useEffect(() => {
    if (selectedWithoutPresets?.length === 0) setManualMode(false)
    else setManualMode(true)
  }, [selected])

  const handlePresetmode = (preset: PresetRange | PresetRange[]) => () => {
    handleChange(preset)
  }

  const handleManualMode = (id: string, slug: string) => (time: Moment) => {
    const timeRange = timeRanges.find(tr => tr.id === id)
    const oldValue = `${timeRange.start}-${timeRange.end}`

    timeRange[slug] = moment(time).format('HH:mm:ss')
    if (timeRange.start && timeRange.end) {
      handleChange(`${timeRange.start}-${timeRange.end}`, oldValue)
    }
  }

  return (
    <div className="multi_time_range">
      <div className="preset-container">
        <button
          type="button"
          className={selected?.includes(PresetRange.FULL) ? 'selected' : ''}
          onClick={handlePresetmode(PresetRange.FULL)}
        >
          {terms.Widgets.filterModal.labels.hourPeak}
        </button>
        <button
          type="button"
          className={
            (selected?.includes(PresetRange.OFF_PEAK_EVENING) && selected?.includes(PresetRange.OFF_PEAK_MORNING))
              ? 'selected'
              : ''
          }
          onClick={async () => {
            handlePresetmode([PresetRange.OFF_PEAK_MORNING, PresetRange.OFF_PEAK_EVENING])()
          }}
        >
          {terms.Widgets.filterModal.labels.hourOff}
        </button>
        <button
          type="button"
          className={manualMode ? 'selected' : ''}
          onClick={() => setManualMode(true)}
        >
          {terms.Widgets.filterModal.labels.manualRange}
        </button>
      </div>
      <div className="info">
        {(selected?.includes(PresetRange.OFF_PEAK_EVENING) && selected?.includes(PresetRange.OFF_PEAK_MORNING)) && (
          <p dangerouslySetInnerHTML={{
            __html: terms.Widgets.filterModal.info.presetRange(
              'heures creuses',
              moment(PresetRange.OFF_PEAK_MORNING.split('-')[0], 'HH:mm:ss').format('HH:mm'),
              moment(PresetRange.OFF_PEAK_MORNING.split('-')[1], 'HH:mm:ss').format('HH:mm'),
              moment(PresetRange.OFF_PEAK_EVENING.split('-')[0], 'HH:mm:ss').format('HH:mm'),
              moment(PresetRange.OFF_PEAK_EVENING.split('-')[1], 'HH:mm:ss').format('HH:mm'),
            ),
          }}
          />
        )}
        {selected?.includes(PresetRange.FULL) && (
          <p dangerouslySetInnerHTML={{
            __html: terms.Widgets.filterModal.info.presetRange(
              'heures pleines',
              moment(PresetRange.FULL.split('-')[0], 'HH:mm:ss').format('HH:mm'),
              moment(PresetRange.FULL.split('-')[1], 'HH:mm:ss').format('HH:mm'),
            ),
          }}
          />
        )}
      </div>
      {manualMode && (
        <div className="manual-container">
          <FormLabel className="label">
            <p>{terms.Widgets.filterModal.labels.addManuelRange}</p>
            <AddIcon className="range-action" onClick={addNewTimeRange} />
          </FormLabel>
          <div className="inputs">
            <div className="header">
              <p>{terms.Widgets.filterModal.labels.hourStart}</p>
              <p>{terms.Widgets.filterModal.labels.hourEnd}</p>
            </div>
            {timeRanges?.map(range => (
              <div className="range" key={range.id}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                  <div className="input">
                    <ClockIcon />
                    <MobileTimePicker
                      value={range.start ? moment(range.start, 'HH:mm:ss') : null}
                      onChange={handleManualMode(range.id, 'start')}
                    />
                  </div>
                  <div className="input">
                    <ClockIcon />
                    <MobileTimePicker
                      value={range.end ? moment(range.end, 'HH:mm:ss') : null}
                      onChange={handleManualMode(range.id, 'end')}
                    />
                  </div>
                </LocalizationProvider>
                <RemoveIcon className="range-action" onClick={removeTimeRange(range.id)} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomMultitimeRange
