/* eslint-disable max-len */
export const terms = {
  Routes: {
    zones: {
      title: 'Zones',
      path: '/zones/',
      tabTitle: 'Zones d\'évaluation de la performance',
    },
    zone: {
      title: 'Zone',
      path: '/zones/:id/',
    },
    dashboards: {
      title: 'Tableaux de bord',
      path: '/dashboards/:id/',
    },
    updateZone: {
      title: 'Mettre à jour une zone',
      path: '/update-zone/:id/',
    },
    signalBox: {
      title: 'Postes d’Aiguillages',
      path: '/signals/',
    },
  },
  Common: {
    loading: 'Chargement',
    search: 'Rechercher',
    return: 'Retour',
    startDate: 'Date de début',
    endDate: 'Date de fin',
  },
  Error: {
    forbidden: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application.',
      appSubtitle: 'Veuillez contacter votre administrateur.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
      exitButton: 'Retour au portail des applications',
    },
  },
  Zones: {
    zone: 'Zone: ',
    allZones: 'Toutes les zones',
    Card: {
      lastModified: 'Modifié :',
      accessZone: 'Accéder à la zone',
      askForAccess: 'Demander l\'accès',
      sharedInfo: 'Partage',
    },
    ZonesTypes: {
      segments: 'Sections de lignes',
      points: 'Ensemble de Gares individuelles',
    },
    CreateZone: {
      title: 'Créer une zone d’évaluation de la performance',
      placeholderType: 'Sections de lignes OU Ensemble de Gares individuelles',
      buttonValidate: 'Continuer',
      labels: {
        name: 'Nom de la zone',
        description: 'Description',
        type: 'Type de zone',
      },
      copySuffix: ' - copie',
      defaultZone: 'Définir comme zone par défaut',
    },
    ShareZone: {
      title: 'Partager la zone',
      labelButton: 'Partager la zone',
      optionSelect: {
        none: 'Sélectionner un groupe',
        read: 'Lecture seule',
        write: 'Éditeur',
      },
    },
    ModifyZone: {
      title: 'Modifier la zone d’évaluation de la performance',
      labels: {
        name: 'Nom de la zone',
        description: 'Description',
      },
      buttonModify: 'Sauvegarder',
    },
    DeleteZone: {
      title: 'Supprimer la zone d’évaluation de la performance',
      content: 'Vous êtes sur le point de supprimer la zone suivante :',
      warning: 'Attention, cette action est irréversible !',
      buttonDelete: 'Supprimer',
    },
    Dropdown: {
      favoriteAdd: 'Mettre en favori',
      favoriteRemove: 'Retirer des favoris',
      duplicate: 'Dupliquer',
      delete: 'Supprimer',
      share: 'Partager',
      edit: 'Modifier',
    },
    Snackbar: {
      createSuccess: 'La zone a été créée avec succès.',
      duplicateSuccess: 'La zone a été dupliquée avec succès.',
      createError: 'Une erreur est survenue lors de la création de la zone.',
      modifySuccess: 'La zone a été modifiée avec succès.',
      duplicateError: 'Une erreur est survenue lors de la duplication de la zone.',
      modifyError: 'Une erreur est survenue lors de la modification de la zone.',
      deleteSuccess: 'La zone a été supprimée avec succès.',
      deleteError: 'Une erreur est survenue lors de la suppression de la zone.',
    },
  },
  Components: {
    FilterHeader: {
      default: 'Par défaut',
      favorites: 'Favoris',
      sortBy: 'Trier par:',
      createZone: 'Créer une zone',
      sortOptions: {
        modificationDate: 'Date de modification',
        name: 'Ordre alphabétique',
      },
    },
  },
  Dashboard: {
    labelAllBoard: 'Tous les tableaux de bord ',
    labelAddBoard: 'Créer un tableau de bord',
    labelHeader: 'tableaux de bord',
    Card: {
      accessBoard: 'Accéder au tableau de bord',
    },
    tab: {
      saveButton: 'Sauvegarder la position des widgets',
    },
    Widgets: {
      info: {
        title: 'Informations du widget',
        params: {
          name: 'Nom',
          metric: 'Métrique',
          type: 'Type de représentation',
          aggregate: 'Agrégation',
          date: 'Période sélectionnée',
        },
      },
    },
    CreateBoard: {
      title: 'Créer un tableau de bord',
      labels: {
        name: 'Nom du tableau de bord',
        description: 'Description',
        default: 'Définir comme tableau de bord par défaut',
      },
      buttonValidate: 'Créer le tableau de bord',
    },
    DeleteDashboard: {
      title: 'Supprimer le tableau de bord',
      content: 'Vous êtes sur le point de supprimer le tableau de bord suivant :',
      warning: 'Attention, cette action est irréversible !',
      buttonDelete: 'Supprimer',
    },
    CreateWidget: {
      title: 'Créer un widget',
      labels: {
        name: 'Nom du widget',
        metricChoice: 'Choix de la métrique',
        metricType: 'Représentation de la métrique',
        metricAggregate: 'Agrégation',
        startDate: 'Date de début',
        endDate: 'Date de fin',
        axis: 'Axe horizontal',
        timeStep: 'Pas temporel',
      },
      types: {
        map: 'Cartographie',
        chart: 'Graphique linéaire',
        bar: 'Graphique en barres',
      },
      buttonValidate: 'Continuer',
      preview: 'Prévisualisation',
    },
    Snackbar: {
      createSuccess: 'Le dashboard a été créée avec succès.',
      duplicateSuccess: 'Le dashboard a été dupliquée avec succès.',
      createError: 'Une erreur est survenue lors de la création du dashboard.',
      modifySuccess: 'Le dashboard a été modifiée avec succès.',
      duplicateError: 'Une erreur est survenue lors de la duplication du dashboard.',
      modifyError: 'Une erreur est survenue lors de la modification du dashboard.',
      deleteSuccess: 'Le dashboard a été supprimée avec succès.',
      deleteWidgetSuccess: 'Le widget a été supprimée avec succès.',
      deleteError: 'Une erreur est survenue lors de la suppression du dashboard.',
      deleteWidgetError: 'Une erreur est survenue lors de la suppression du widget.',
    },

    Map: {
      Settings: {
        labelButton: 'Modifier la zone',
      },
    },

    ModifyBoard: {
      title: 'Modifier le tableau de bord',
      labels: {
        name: 'Nom du tableau de bord',
        description: 'Description',
      },
      buttonModify: 'Modifier le tableau de bord',
    },
  },
  Widgets: {
    create: {
      title: 'Créer un widget',
      labels: {
        name: 'Nom du widget',
        metricChoice: 'Choix de la métrique',
        metricType: 'Représentation de la métrique',
        agregate: 'Agrégation',
      },
    },
    filterModal: {
      title: 'Filtres du widget',
      labels: {
        hourPeak: 'Heures pleines',
        hourOff: 'Heures Creuses',
        hourStart: 'Heure de début',
        rangeStart: 'Tranche de début',
        rangeEnd: 'Tranche de fin',
        hourEnd: 'Heure de fin',
        manualRange: 'Plage manuelle',
        addManuelRange: 'Ajouter plage manuelle',
        choose: 'Choisir...',
      },
      buttons: {
        apply: 'Continuer',
        reset: 'Réinitialiser les filtres',
      },
      info: {
        presetRange: (label: string, ...dates: string[]) => (
          `Les <b>${label}</b> sont comprises entre ${dates.join(' et ')}.`
        ),
      },
    },
    noData: 'Aucune données à afficher.',
    errorLoadingData: 'Une erreur est survenue lors du chargement des données.',
  },
  Map: {
    Toolbar: {
      lightTheme: 'Fond de carte sobre',
      fullTheme: 'Fond de carte riche',
      resetMapView: 'Réinitialiser la vue',
      sncfRegion: 'SNCF',
      adminRegion: 'Administratives',
      borders: 'Frontières',
    },
    Popup: {
      Circulation: {
        type: 'Type',
        number: 'N°',
        origin: 'ORIGINE',
        passage: 'PASSAGE',
        departure: 'DÉPART',
        arrival: 'ARRIVÉE',
        terminus: 'TERMINUS',
        noTimeline: 'Aucune information de circulation',
        minutes: 'min',
      },
      ci: 'Code CI',
      ch: 'Code CH',
      ciLibelle: 'Libellé CI',
      chLibelle: 'Libellé CH',
      gaiaId: 'ID Gaïa',
      eic: 'EIC',
    },
    Layer: {
      Category: {
        infrastructure: 'Infrastructure',
        circulationState: 'État des circulations',
      },
      pr: 'Point remarquable',
      onTime: 'A l\'heure',
      late: 'Léger retard',
      veryLate: 'Retard important',
    },
  },
  UpdateZone: {
    header: {
      backLabel: 'Retour à l’étape précédente',
      buttonLabel: 'Sauvegarder',
      closeHover: 'Retour à la page d’accueil',
      toggleBV: 'Afficher les gares',
    },
    modal: {
      labelButtonSegments: 'Ajouter ou supprimer une section',
      labelButtonPoints: 'Ajouter ou supprimer une Gare',
    },
    panel: {
      inputPointPlaceholder: 'Sélectionner une gare depuis la carte',
      inputSectionPlaceholder: 'Cliquez sur un PR/BV de la carte',
      inputError: 'Champ à remplir obligatoirement',
      addPointButtonLabel: 'Ajouter une Gare',
      addSectionButtonLabel: 'Ajouter une section',
      addPrWarning: 'Veuillez ajouter votre premiere Gare avant d’en ajouter une autre',
      addSectionWarning: 'Veuillez ajouter votre premier segment avant d’en créer un autre',
      addIntermediatePr: 'Ajouter une Gare intermédiaire',
    },
    map: {
      wrongSelect: 'Veuillez sélectionner un code_ch = BV ou 00',
    },
  },
}

export default terms
