import { ChangeEvent, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import terms from 'common/terms'
import './customMultiRange.scss'

type Props = {
  selected: string[]
  handleChange: (value: string, toReplace?: string) => void
}

const CustomMultiRange = ({ selected, handleChange }: Props) => {
  const [ranges, setRanges] = useState<{start: string, end: string, id: string}[]>(
    selected ? selected?.map(range => {
      const [start, end] = range.split('-')
      return { start, end, id: uuidv4() }
    }) : [{ start: '', end: '', id: uuidv4() }],
  )

  useEffect(() => {
    if (!selected) setRanges([{ start: '', end: '', id: uuidv4() }])
  }, [selected])

  const addNewRange = () => {
    setRanges([...ranges, { start: '', end: '', id: uuidv4() }])
  }

  const removeRange = (id: string) => () => {
    const range = ranges.find(tr => tr.id === id)
    const rangesFiltered = ranges.filter(tr => tr.id !== id)
    if (range?.start && range?.end) handleChange(`${range.start}-${range.end}`)
    setRanges(rangesFiltered)
  }

  const handleInputChange = (id: string, slug: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const range = ranges.find(tr => tr.id === id)
    const oldValue = `${range.start}-${range.end}`

    range[slug] = event.target.value
    handleChange(`${range.start}-${range.end}`, oldValue)
  }

  return (
    <div className="multi_input_range">
      <AddIcon className="range-action" onClick={addNewRange} />
      <div className="inputs">
        <div className="header">
          <p>{terms.Widgets.filterModal.labels.rangeStart}</p>
          <p>{terms.Widgets.filterModal.labels.rangeEnd}</p>
        </div>
        {ranges?.map(range => (
          <div className="range" key={range.id}>
            <div className="input">
              <input type="text" defaultValue={range.start} onChange={handleInputChange(range.id, 'start')} />
            </div>
            <div className="input">
              <input type="text" defaultValue={range.end} onChange={handleInputChange(range.id, 'end')} />
            </div>
            <RemoveIcon className="range-action" onClick={removeRange(range.id)} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default CustomMultiRange
