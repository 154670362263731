const secondsToDhms = seconds => {
  const sign = Math.sign(seconds)
  seconds = Math.abs(seconds)
  const d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const dDisplay = d > 0 ? `${d}j ` : ''
  const hDisplay = h > 0 ? `${h}h ` : ''
  const mDisplay = m > 0 ? `${m}min` : ''
  return (sign === 1 ? '' : '-') + dDisplay + hDisplay + mDisplay
}

const yScaleFormat = seconds => {
  const sign = Math.sign(seconds)
  seconds = Math.abs(seconds)
  const d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  if (d > 3) {
    return `${(sign === 1 ? '' : '-')} ${d} jours`
  } if (d === 0) {
    return `${(sign === 1 ? '' : '-')} ${h}h ${m}m`
  }
  return `${(sign === 1 ? '' : '-')} ${d}j ${h}h ${m}m`
}

const handleFormatLabel = (value: string | number, unit) => {
  if (unit === 'secondes') {
    return secondsToDhms(value) // Humanize time
  }
  return `${value}${unit}`
}

const handleFormatLeftAxis = (value: string | number, unit) => {
  if (unit === 'secondes') {
    return yScaleFormat(value) // Humanize time
  }
  return `${value}${unit}`
}

export {
  secondsToDhms,
  yScaleFormat,
  handleFormatLabel,
  handleFormatLeftAxis,
}
