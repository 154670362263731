import { PopupContent } from '../types'

const FIELDS_TO_SHOW: PopupContent[] = [
  {
    name: 'code_ci',
    translation: 'ci',
  },
  {
    name: 'code_ch',
    translation: 'ch',
  },
  {
    name: 'libelle',
    translation: 'chLibelle',
  },
  {
    name: 'id',
    translation: 'gaiaId',
  },
]

export default FIELDS_TO_SHOW
