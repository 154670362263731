import { LAYER_NAMES } from 'services/map'
import { LayerProperties } from '../types'

const KpLayerPaint: LayerProperties = {
  kpLayer: {
    type: 'circle',
    id: LAYER_NAMES.pkLayer,
    minzoom: 11.5,
    paint: {
      'circle-color': '#6d85c9',
      'circle-radius': 3,
    },
  },
  kpLibelleLayer: {
    minzoom: 12,
    id: `${LAYER_NAMES.pkLayer}-name`,
    type: 'symbol',
    layout: {
      'text-font': ['Open Sans Regular'],
      'text-size': 12,
      'text-anchor': 'bottom',
      'text-offset': [0, -0.5],
      'text-field': ['concat', 'PK ', ['slice', ['get', 'pk'], 0, -4]],
    },
    paint: {
      'text-color': '#6d85c9',
    },
  },
}

export default KpLayerPaint
