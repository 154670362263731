import { ReactElement } from 'react'

import './customButtonCheck.scss'

interface Props {
  list: string[],
  selected: string[]
  handleclick: (value: string) => void,
}

const CustomButtonCheck = ({
  list,
  selected,
  handleclick,
}: Props): ReactElement => (
  <div className="custom-button-check">
    <div className="button-container">
      {
      list?.map(item => (
        <button
          key={item}
          type="button"
          onClick={() => handleclick(item)}
          className={selected?.includes(item) ? 'selected' : ''}
        >
          {item}
        </button>
      ))
    }
    </div>
  </div>
)

export default CustomButtonCheck
