import terms from 'common/terms'
import CustomButton from 'components/CustomButton/CustomButton'
import CustomModal from 'components/CustomModal/CustomModal'
import { ReactElement } from 'react'
import { Board } from 'reducers/boards/types'
import './modals.scss'

interface Props {
  onModal: boolean
  handleClose: () => void
  selectedBoard: Board | null
  handleDelete: () => void
}

const DeleteZoneModal = ({
  onModal, handleClose, selectedBoard, handleDelete,
}: Props): ReactElement => (
  <CustomModal
    open={onModal}
    handleClose={handleClose}
    title={terms.Dashboard.DeleteDashboard.title}
    className="modal-zone"
  >
    <h3 className="modal-zone-text">
      {terms.Dashboard.DeleteDashboard.content}
    </h3>
    <h3 className="modal-zone-text">
      {`"${selectedBoard?.name}"`}
    </h3>
    <h3 className="modal-zone-text">
      {terms.Dashboard.DeleteDashboard.warning}
    </h3>
    <CustomButton
      title={terms.Dashboard.DeleteDashboard.buttonDelete}
      handleClick={() => {
        handleDelete()
        handleClose()
      }}
      className="modal-zone-button"
      variant="primary"
    />
  </CustomModal>
)

export default DeleteZoneModal
