import {
  FocusEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import locationCircleIcon from 'assets/icons/locationCircle.svg'
import locationRectangleIcon from 'assets/icons/locationRectangle.svg'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CancelIcon from '@mui/icons-material/Cancel'
import terms from 'common/terms'
import { Point } from 'reducers/zones/types'
import './style.scss'

type Props = {
  placeholder: string
  isFirst: boolean
  isLast: boolean
  intermediates?: Point[]
  value?: string
  isSelected: boolean
  isDeletable?: boolean
  hasError?: boolean
  onDelete?: () => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
}

const PRInput = ({
  value, placeholder, isFirst, isLast, isSelected, onDelete, onFocus, hasError, isDeletable, intermediates,
}: Props) => {
  const [val, setVal] = useState('')
  const intermediatesWrapperRef = useRef<HTMLDivElement>()
  const [selected, setSelected] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const expandable = intermediates.length > 0

  // Calculate and update the height of the intermediate wrapper
  useEffect(() => {
    if (intermediatesWrapperRef.current) {
      intermediatesWrapperRef.current.style.height = expanded
        ? `calc(${(intermediates.length - 1) * 32}px + 58px)`
        : '0'
    }
  }, [intermediates, expanded])

  useEffect(() => {
    setVal(value)
  }, [value])

  useEffect(() => {
    setSelected(isSelected)
  }, [isSelected])

  // Generate a location icon based on the input position
  const getIcon = (): JSX.Element => {
    let icon: JSX.Element

    if (isLast) {
      icon = <LocationOnIcon className="on" />
    } else if (isFirst) {
      icon = (
        <>
          <img src={locationRectangleIcon} className="rectangle" alt="location" />
          <div className="dots" />
        </>
      )
    } else {
      icon = (
        <>
          <img src={locationCircleIcon} className="circle" alt="location" />
          <div className="dots" />
        </>
      )
    }

    return icon
  }

  const classes = useMemo(() => [
    'pr-input',
    selected ? 'selected' : '',
    hasError ? 'error' : '',
    expandable ? 'expandable' : '',
    expanded ? 'expanded' : '',
  ].filter(cls => !!cls).join(' '), [selected, hasError, expanded])

  return (
    <div
      className={classes}
      onFocus={onFocus}
    >
      <div
        role="button"
        tabIndex={0}
        className="location-icon"
        onClick={() => expandable && setExpanded(prev => !prev)}
      >
        {getIcon()}
      </div>
      <input
        type="text"
        placeholder={placeholder}
        value={val}
        readOnly
      />
      {(isDeletable && !!onDelete) && <CancelIcon onClick={onDelete} className="delete-icon" />}
      <span>
        <InfoOutlinedIcon />
        {terms.UpdateZone.panel.inputError}
      </span>
      {intermediates?.length > 0 && (
      <div
        ref={intermediatesWrapperRef}
        className="intermediates"
      >
        {intermediates.map(({ label }) => <span key={label}>{label}</span>)}
      </div>
      )}
    </div>
  )
}

PRInput.defaultProps = {
  value: '',
  hasError: '',
  isDeletable: false,
  intermediates: [],
  onFocus: () => { /* to implement */ },
  onDelete: () => { /* to implement */ },
}

PRInput.displayName = 'PRInput'

export default PRInput
