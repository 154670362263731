/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { MouseEvent, useEffect } from 'react'
import WidgetsIcon from '@mui/icons-material/Widgets'
import AddIcon from '@mui/icons-material/Add'
import HomeIcon from '@mui/icons-material/Home'
import CloseIcon from '@mui/icons-material/Close'
import history from 'utils/history'
import terms from 'common/terms'
import { Board } from 'reducers/boards/types'
import { removeBoardFromOpenBoards } from 'reducers/boards/boards.reducers'
import boardsSelectors from 'reducers/boards/boards.selectors'

import './Tab.scss'

type Props = {
  // TODO: zoneId should be retrieved from board object when API will provide it
  zoneId: string
  enablePositionActions?: boolean
  toggleEnablePositionActions?: () => void
  onAddAction?: () => void
  onSavePositions?: () => void
  isHome?: boolean
  disableActions?: boolean
}

const Tab = ({
  zoneId,
  enablePositionActions,
  toggleEnablePositionActions,
  isHome,
  onAddAction,
  onSavePositions,
  disableActions,
}: Props) => {
  const { id } = useParams() as { id: string }
  const dispatch = useDispatch()
  const openedBoards = useSelector(boardsSelectors.getOpenedBoards(zoneId))

  useEffect(() => {
    if (!isHome && openedBoards.length === 0) {
      if (zoneId) {
        history.replace(`/zones/${zoneId}`)
      } else {
        history.replace('/zones/')
      }
    }
  }, [openedBoards.length])

  const handleSelectBoard = (boardId: string) => () => {
    history.replace(`/dashboards/${boardId}?zone_id=${zoneId}`)
  }

  const handleRemoveBoard = (board: Board) => (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation()

    // Select the closest board
    const nextBoard = openedBoards.find(openedBoard => openedBoard.id !== board.id)
    if (nextBoard && !isHome) {
      handleSelectBoard(nextBoard.id)()
    }

    dispatch(removeBoardFromOpenBoards({ zoneId, boardId: board.id }))
  }

  if (openedBoards.length === 0) return null

  return (
    <div className="dashboard-tabs">
      <div className="list">
        <div
          className={`flex-center${isHome ? ' home' : ''}`}
          onClick={() => zoneId && history.replace(`/zones/${zoneId}`)}
        >
          <HomeIcon />
        </div>
        {openedBoards.map(board => (
          <div
            key={board?.id}
            className={`tab${id === board?.id ? ' selected' : ''} flex-center`}
            onClick={handleSelectBoard(board?.id)}
          >
            <span>{board?.name}</span>
            <CloseIcon onClick={handleRemoveBoard(board)} />
          </div>
        ))}
      </div>
      {!isHome && !disableActions && (
      <div className="actions">
        {enablePositionActions && (
        <button type="button" className="no-style flex-center" onClick={onSavePositions}>
          {terms.Dashboard.tab.saveButton}
        </button>
        )}
        <div
          className={`flex-center${enablePositionActions ? ' selected' : ''}`}
          onClick={toggleEnablePositionActions}
        >
          <WidgetsIcon />
        </div>
        <div className="flex-center" onClick={onAddAction}>
          <AddIcon />
        </div>
      </div>
      )}
    </div>
  )
}

Tab.defaultProps = {
  enablePositionActions: false,
  toggleEnablePositionActions: () => { /* to implement */ },
  isHome: false,
  disableActions: false,
  onAddAction: () => { /* to implement */ },
  onSavePositions: () => { /* to implement */ },
}
export default Tab
