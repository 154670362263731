import { ReactElement, useEffect, useState } from 'react'
import {
  FormGroup, FormLabel, OutlinedInput, FormControlLabel, Checkbox,
} from '@mui/material'
import terms from 'common/terms'
import CustomButton from 'components/CustomButton/CustomButton'
import CustomModal from 'components/CustomModal/CustomModal'
import { isAdmin } from 'utils'
import { ParamsCreateBoard } from 'reducers/boards/types'

import './modals.scss'

interface Props {
  onModal: boolean;
  handleClose: () => void;
  handleSubmit: (data: ParamsCreateBoard) => void;
  zoneId: string;
}

const DashboardCreateModal = ({
  onModal, handleClose, handleSubmit, zoneId,
}: Props): ReactElement => {
  const [boardTitle, setBoardTitle] = useState<string>('')
  const [boardDescription, setBoardDescription] = useState<string>('')
  const [boardDefault, setBoardDefault] = useState<boolean>(false)

  useEffect(() => {
    setBoardTitle('')
    setBoardDescription('')
    setBoardDefault(false)
  }, [onModal])

  const handleSave = () => {
    handleSubmit({
      name: boardTitle,
      description: boardDescription,
      default: boardDefault,
      zone_id: zoneId,
    })
    handleClose()
  }

  return (
    <CustomModal
      open={onModal}
      handleClose={handleClose}
      title={terms.Dashboard.CreateBoard.title}
      className="modal-board"
      variant="secondary"
    >
      <FormGroup className="form-group">
        <FormLabel className="label" required>
          {terms.Dashboard.CreateBoard.labels.name}
        </FormLabel>
        <OutlinedInput
          value={boardTitle}
          onChange={event => setBoardTitle(event.target.value)}
        />
      </FormGroup>
      <FormGroup className="form-group">
        <FormLabel className="label">
          {terms.Dashboard.CreateBoard.labels.description}
        </FormLabel>
        <OutlinedInput
          aria-label="minimum height"
          multiline
          minRows={8}
          maxRows={8}
          value={boardDescription}
          onChange={event => setBoardDescription(event.target.value)}
        />
      </FormGroup>
      {isAdmin() && (
        <FormGroup>
          <FormControlLabel
            className="checkbox"
            control={(
              <Checkbox
                className="checkbox-input"
                checked={boardDefault}
                onChange={() => setBoardDefault(!boardDefault)}
              />
          )}
            label={terms.Dashboard.CreateBoard.labels.default}
          />
        </FormGroup>
      )}
      <CustomButton
        disabled={(boardTitle === '')}
        className="button"
        title={terms.Dashboard.CreateBoard.buttonValidate}
        handleClick={handleSave}
        variant="secondary"
      />
    </CustomModal>
  )
}

export default DashboardCreateModal
