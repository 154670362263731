import terms from 'common/terms'
import CustomButton from 'components/CustomButton/CustomButton'
import CustomModal from 'components/CustomModal/CustomModal'
import { ReactElement } from 'react'
import { Zone } from 'reducers/zones/types'
import './zoneModal.scss'

interface Props {
  onModal: boolean
  handleClose: () => void
  selectedZone: Zone | null
  handleDelete: () => void
}

const DeleteZoneModal = ({
  onModal, handleClose, selectedZone, handleDelete,
}: Props): ReactElement => (
  <CustomModal open={onModal} handleClose={handleClose} title={terms.Zones.DeleteZone.title} className="modal-zone">
    <h3 className="modal-zone-text">
      {terms.Zones.DeleteZone.content}
    </h3>
    <h3 className="modal-zone-text">
      {`"${selectedZone?.name}"`}
    </h3>
    <h3 className="modal-zone-text">
      {terms.Zones.DeleteZone.warning}
    </h3>
    <CustomButton
      title={terms.Zones.DeleteZone.buttonDelete}
      handleClick={() => {
        handleDelete()
        handleClose()
      }}
      className="modal-zone-button"
      variant="primary"
    />
  </CustomModal>
)

export default DeleteZoneModal
