import { AppDispatch } from 'reducers/store'

export type DispatchFunction = (dispatch: AppDispatch) => void

export type UnknownObject = { [k: string]: unknown }

export type ResponseError = {
  status: number;
  message: UnknownObject;
}

export enum FilterSortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum FilterSortBy {
  LAST_MODIFIED = 'last_modified',
  NAME = 'name',
}

export type FilterSettings = {
  sortBy: FilterSortBy,
  sortDirection: FilterSortDirection,
  search: string,
  favorites: boolean,
  defaults: boolean,
}
