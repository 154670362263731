import terms from 'common/terms'
import { RpFeatures } from 'components/Layers/types'
import { ReactElement } from 'react'
import get from 'lodash/get'
import FIELDS_TO_SHOW from './fields'

import './rpPopup.scss'

type Props = {
  properties: RpFeatures;
}
export default function RpPopup({ properties }: Props): ReactElement {
  return (
    <>
      <div className="rpPopup-header">
        {properties.libelle}
      </div>

      <div className="rpPopup-body mapboxgl-popup-container">

        {FIELDS_TO_SHOW.map(field => (
          <div key={field.name} className="popup-content">
            <span className="rpPopup-body-title">{`${get(terms.Map.Popup, field.translation)} : `}</span>
            <span>{properties[field.name]}</span>
          </div>
        ))}
      </div>
    </>
  )
}
