import { Box, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ReactElement } from 'react'
import './CustomModal.scss'

interface Props {
  open: boolean
  handleClose: () => void
  children: ReactElement | ReactElement[]
  title: string
  className?: string
  variant?: 'primary' | 'secondary'
}

const CustomModal = ({
  open, handleClose, children, className, title, variant = 'primary',
}: Props): ReactElement => (
  <Modal open={open} onClose={handleClose}>
    <Box className={`custom-modal ${className}`}>
      <div className={`custom-modal-header ${variant}`}>
        <h2>{title}</h2>
        <button type="button" onClick={handleClose} tabIndex={0}>
          <CloseIcon />
        </button>
      </div>
      <div className={`custom-modal-content ${variant}`}>
        {children}
      </div>
    </Box>
  </Modal>
)

CustomModal.defaultProps = {
  className: undefined,
  variant: 'primary',
}

export default CustomModal
