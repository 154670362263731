import { get, post } from '@osrdata/app_core/dist/requests'
import { Feature, Point as GeoPoint } from 'geojson'
import { Point, Section, Zone } from 'reducers/zones/types'

export const getFlatZoneElements = (zone: Zone) => zone?.components?.flatMap(s => s.elements)

export const getZonePrIds = (points: Point[]) => points
  .filter(element => !element.intermediate)
  .map(element => element.gaia_id)
  .filter(Boolean)

export const getTracks = async (prIds: string[]) => {
  const { geometry, steps } = await post('/cassini-v2/route/rgi_line_geo/pr/', {
    pr_list: prIds,
  })

  return { geometry, steps } as { geometry: GeoJSON.Geometry, steps: (Partial<Point> & { libelle: string })[] }
}

export const getPrById = async (id: string): Promise<Feature<GeoPoint>> => {
  const { features } = await get(
    '/chartis/v2/layer/cassini_v2_gaia_point_remarquable/geojson/full_rgi_line_geo_centroid/',
    { id },
  )

  return features?.at(0)
}

export const getSectionLabelValue = (section: Section) => {
  if (section.label) {
    return section.label
  }

  const firstLabel = section?.elements?.at(0)?.label || ''
  const lastLabel = section?.elements?.at(section.elements.length - 1)?.label || ''

  return `${firstLabel} <-> ${lastLabel}`
}

export const getZoneThumbnail = (zone: Zone): Promise<string> => fetch(zone?.thumbnail_file, {
  method: 'GET',
  cache: 'no-cache',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  },
})
  .then(response => response.json())
  .then(url => url)
