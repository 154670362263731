import {
  ReactElement, useCallback, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { RootState } from 'reducers/store'
import { FilterSettings } from 'reducers/types'
import { Zone } from 'reducers/zones/types'
import terms from 'common/terms'
import CardList from 'components/CardList/CardList'
import CardWrapper from 'components/CardWrapper/CardWrapper'
import FilterHeader from 'components/FilterHeader/FilterHeader'
import { setFilterSettings } from 'reducers/zones/zones.reducers'
import {
  copyZone,
  deleteZone, getZones, patchZones, postZones,
} from 'reducers/zones/zones.thunks'
import {
  handleDefaultClick, handleFavoriteClick, handleSortDirection, handleSortType,
} from 'common/functions'
import ZoneCard from './ZoneCard/ZoneCard'
import CreateZoneModal from './ZoneModal/CreateZoneModal'
import ModifyZoneModal from './ZoneModal/ModifyZoneModal'
import DeleteZoneModal from './ZoneModal/DeleteZoneModal'
import ShareZoneModal from './ZoneModal/ShareZoneModal'
import './ZonePage.scss'

const ZonePage = (): ReactElement => {
  const dispatch = useDispatch()
  const { filterSettings, list } = useSelector((state: RootState) => state.zones)
  const [selectedZone, setSelectedZone] = useState<Zone | null>(null)
  const [displayModal, setDisplayModal] = useState<'add' | 'patch' | 'delete' | 'share'>(null)
  const debouncedSearch = useCallback(debounce(
    (newSettings: FilterSettings) => dispatch(getZones(newSettings)), 250,
  ), [])

  useEffect(() => {
    dispatch(getZones(filterSettings))
  }, [])

  // Filter handlers
  const handleFilterChange = (newSettings: FilterSettings) => {
    dispatch(setFilterSettings(newSettings))
    dispatch(getZones(newSettings))
  }
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSettings = { ...filterSettings, search: event.target.value }
    dispatch(setFilterSettings(newSettings))
    debouncedSearch(newSettings)
  }

  // Card handlers
  const handleFavorite = (zone: Zone) => () => {
    dispatch(patchZones({ zoneId: zone.id, favorite: !zone.favorite }))
  }
  const handleCopy = (zone: Zone) => () => {
    dispatch(copyZone(zone.id))
  }
  const handleModify = (zone: Zone) => () => {
    setSelectedZone(zone)
    dispatch(setDisplayModal('patch'))
  }
  const handleDelete = (zone: Zone) => () => {
    setSelectedZone(zone)
    dispatch(setDisplayModal('delete'))
  }
  const handleShare = (zone: Zone) => () => {
    setSelectedZone(zone)
    dispatch(setDisplayModal('share'))
  }

  // Modal handlers
  const handleCloseModal = () => dispatch(setDisplayModal(null))
  const handleModifyZone = (newZone: string, newDescription: string) => {
    dispatch(patchZones({ zoneId: selectedZone?.id || '', name: newZone, description: newDescription }))
  }
  const handleDeleteZone = () => {
    if (selectedZone) {
      dispatch(deleteZone(selectedZone.id))
    }
  }

  return (
    <>
      <div id="zone-page">
        <CardList>
          <FilterHeader
            handleDefaultClick={() => handleDefaultClick(handleFilterChange, filterSettings)}
            handleFavoriteClick={() => handleFavoriteClick(handleFilterChange, filterSettings)}
            allElementsLabel={terms.Zones.allZones}
            allElementsCount={list.length}
            handleSearchChange={handleSearchChange}
            filterSettings={filterSettings}
            handleChangeSortType={() => handleSortType(handleFilterChange, filterSettings)}
            handleChangeSortDirection={() => handleSortDirection(handleFilterChange, filterSettings)}
            buttonLabel={terms.Components.FilterHeader.createZone}
            handleAddClick={() => dispatch(setDisplayModal('add'))}
            variant="primary"
          />
          <>
            {list.map(zone => (
              <CardWrapper key={zone.id}>
                <ZoneCard
                  zone={zone}
                  handleClickFavorite={handleFavorite(zone)}
                  handleClickCopy={handleCopy(zone)}
                  handleClickModify={handleModify(zone)}
                  handleClickDelete={handleDelete(zone)}
                  handleClickShare={handleShare(zone)}
                />
              </CardWrapper>
            ))}
          </>
        </CardList>
      </div>
      <CreateZoneModal
        onModal={displayModal === 'add'}
        handleClose={handleCloseModal}
        handleSubmit={data => dispatch(postZones(data)) as unknown as { payload: { id: string }}}
      />
      <ModifyZoneModal
        onModal={displayModal === 'patch'}
        handleClose={handleCloseModal}
        selectedZone={selectedZone}
        handleModify={handleModifyZone}
      />
      <DeleteZoneModal
        onModal={displayModal === 'delete'}
        handleClose={handleCloseModal}
        selectedZone={selectedZone}
        handleDelete={handleDeleteZone}
      />
      <ShareZoneModal
        onModal={displayModal === 'share'}
        selectedZone={selectedZone}
        handleClose={handleCloseModal}
      />
    </>
  )
}

export default ZonePage
