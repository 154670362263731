import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'
import { Zone } from 'reducers/zones/types'
import SectionLinePaint from './SectionLinePaint'

type Props = {
  zone: Zone
}

export default function SectionLineLayer({ zone }: Props): ReactElement {
  if (!zone?.components || !zone?.components[0]) return null

  return (
    <Source
      id="test-new"
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features: zone?.tracks || [],
      }}
    >
      <Layer
        {...SectionLinePaint.track}
      />
    </Source>
  )
}
