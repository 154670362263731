import { LAYER_NAMES } from 'services/map'
import { LayerProperties } from '../types'

const getBvNoPostLabel: mapboxgl.Expression = ['all', ['any', ['==', ['get', 'code_ch'], 'BV'],
  ['==', ['get', 'code_ch'], '00']], ['!', ['in', 'Poste', ['get', 'libelle']]]]

const RpLayerPaint: LayerProperties = {
  bvLayer: {
    id: LAYER_NAMES.bvLayer,
    type: 'symbol',
    layout: {
      'icon-image': 'station',
      'icon-size': 0.5,
      'icon-allow-overlap': true,
    },
    paint: {},
    filter: getBvNoPostLabel,
  },
  bvLayerHighlighted: {
    id: `${LAYER_NAMES.bvLayer}-highlighted`,
    type: 'symbol',
    layout: {
      'icon-image': 'stationSelected',
      'icon-size': 0.55,
      'icon-allow-overlap': true,
    },
    paint: {},
    filter: getBvNoPostLabel,
  },
  bvLayerEditing: {
    id: `${LAYER_NAMES.bvLayer}-editing`,
    type: 'symbol',
    layout: {
      'icon-image': 'stationEditing',
      'icon-size': 0.55,
      'icon-allow-overlap': true,
    },
    paint: {},
    filter: getBvNoPostLabel,
  },
  bvLayerName: {
    id: `${LAYER_NAMES.bvLayer}-name`,
    minzoom: 9,
    type: 'symbol',
    layout: {
      'text-font': ['Open Sans Regular'],
      'text-size': 14,
      'text-anchor': 'left',
      'symbol-placement': 'point',
      'text-allow-overlap': false,
      'text-offset': [1.5, 0],
      'text-field': '{libelle}',
    },
    paint: {
      'text-color': '#0088ce',
      'text-halo-color': 'hsl(0, 0%, 100%)',
      'text-halo-width': 0.2,
    },
    filter: getBvNoPostLabel,
  },
}

export default RpLayerPaint
