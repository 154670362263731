import {
  Dispatch, ReactElement, SetStateAction,
} from 'react'
import Menu from '@mui/material/Menu'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import './dropDown.scss'

interface DropDownProps {
  children: ReactElement[] | ReactElement;
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement>>;
}

const DropDown = ({ children, anchorEl, setAnchorEl }: DropDownProps): ReactElement => {
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <button
        className="drop-down-button"
        type="button"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {children}
      </Menu>
    </div>
  )
}

export default DropDown
