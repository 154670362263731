import InfoIcon from '@mui/icons-material/InfoOutlined'
import SimpleButton, { ButtonStyle } from 'components/SimpleButton/SimpleButton'
import './style.scss'

type Props = {
  buttonLabel: string
  warningLabel: string
  disabled: boolean
  onClick: () => void
}

const AddButton = ({
  buttonLabel, warningLabel, disabled, onClick,
}: Props) => (
  <div className="action-wrapper">
    {disabled && (
    <p>
      <InfoIcon />
      <span>{warningLabel}</span>
    </p>
    )}
    <SimpleButton
      disabled={disabled}
      onClick={onClick}
      style={ButtonStyle.purple}
      title={buttonLabel}
    />
  </div>
)

export default AddButton
