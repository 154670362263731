import { MAP_LAYER_SOURCE } from 'services/map'
import RpPopup from 'components/Popups/RpPopup/RpPopup'
import { ReactElement } from 'react'
import { MapEvent, Popup } from 'react-map-gl'
import './FeatureClickPopup.scss'

type Props = {
    event: MapEvent;
    onClose: (state: boolean) => void;
}
export default function FeatureClickPopup({
  event, onClose,
}: Props): ReactElement {
  const coords = event?.lngLat

  const buildContentData = (featureEvent: MapEvent) => {
    // todo refacto dupe with map click event
    const featureToShow = featureEvent.features?.length && featureEvent.features[0]
    switch (featureToShow.sourceLayer) {
      case MAP_LAYER_SOURCE.pr:
        return <RpPopup properties={featureToShow.properties} />

      default:
        return <></>
    }
  }
  return (
    <Popup
      longitude={coords[0]}
      latitude={coords[1]}
      closeButton
      captureScroll
      onClose={() => onClose(false)}
      className="mapboxgl-hover-custom-popup"
    >
      {buildContentData(event)}
    </Popup>
  )
}
