import { persistCombineReducers } from 'redux-persist'
import { createFilter } from 'redux-persist-transform-filter'
import storage from 'redux-persist/lib/storage' // defaults to localStorage

import { auth } from '@osrdata/app_core'
import zonesReducers from './zones/zones.reducers'
import mapReducers from './map/map.reducers'
import appReducers from './app/app.reducers'
import boardsReducers from './boards/boards.reducers'

const userReducer = auth.reducer

const userWhiteList = [
  'account',
]

const saveUserFilter = createFilter(
  'user',
  userWhiteList,
)

const persistConfig = {
  key: 'root',
  storage,
  transforms: [saveUserFilter],
  whitelist: ['user'],
}

export const rootReducer = {
  user: userReducer,
  zones: zonesReducers,
  map: mapReducers,
  app: appReducers,
  boards: boardsReducers,
}

const persistedRootReducer = persistCombineReducers(persistConfig, rootReducer)

export default persistedRootReducer
