import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'
import { Point, Zone } from 'reducers/zones/types'
import { MAP_LAYER_SOURCE, formatLayerUrl } from 'services/map'
import BvLayerPaint from './BvLayerPaint'

type Props = {
  zone?: Zone,
  draftPoints?: Point[]
  hideUnselectBV?: boolean // used during map printing process
}

export function BvLayer({ zone, hideUnselectBV }: Props): ReactElement {
  const mode = 'full_rgi_line_geo_centroid'
  const params = 'habillage_statut_rgi_line_geo__overlaps=Exploitée,Transférée en voie de service'
  const points = zone?.components?.flatMap(section => section?.elements) || []
  const ids = [...new Set(points.filter(point => !!point.gaia_id).map(point => point.gaia_id))] || []
  const selected = points.find(point => point.selected) || {}

  return (
    <Source
      id="bv-source"
      type="vector"
      url={formatLayerUrl(MAP_LAYER_SOURCE.pr, mode, params)}
    >

      <Layer
        {...BvLayerPaint.bvLayer}
        source-layer={MAP_LAYER_SOURCE.pr}
        layout={{ ...BvLayerPaint.bvLayer.layout, visibility: hideUnselectBV ? 'none' : 'visible' }}
      />

      {ids.length > 0 && (
        <Layer
          {...BvLayerPaint.bvLayerHighlighted}
          source-layer={MAP_LAYER_SOURCE.pr}
          filter={['in', 'id', ...ids]}
        />
      )}

      {selected?.gaia_id && (
        <Layer
          {...BvLayerPaint.bvLayerEditing}
          source-layer={MAP_LAYER_SOURCE.pr}
          filter={['in', 'id', selected.gaia_id]}
        />
      )}

      <Layer
        {...BvLayerPaint.bvLayerName}
        source-layer={MAP_LAYER_SOURCE.pr}
      />

    </Source>
  )
}

BvLayer.defaultProps = {
  zone: [],
  draftPoints: [],
  hideUnselectBV: false,
}

export default BvLayer
