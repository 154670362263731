import { auth } from '@osrdata/app_core'
import 'App.scss'
import AccessDenied from 'components/AccessDenied/AccessDenied'
import Loader from 'components/Loader/Loader'
import TabList from 'components/TabList/TabList'
import TopBar from 'components/TopBar/TopBar'
import {
  ReactElement, Suspense, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  Router, Switch,
} from 'react-router-dom'
import { RootState } from 'reducers/store'
import { terms } from 'common/terms'
import navRoutes from 'utils/navRoutes'
import navTabElements from 'utils/navTabElements'
import history from 'utils/history'
import AppSnackbar from 'components/AppSnackbar/AppSnackbar'
import { hideSnackbar } from 'reducers/app/app.reducers'
import { getRoles } from 'reducers/app/app.thunk'
import getUserPerimeters from 'reducers/map/map.thunks'

const ACCESS_PERM = 'usage-reseau::ACCESS'

export default function App(): ReactElement {
  const dispatch = useDispatch()
  const { isLogged, appPermissions, isLoading } = useSelector((state: RootState) => state.user)
  const {
    displaySnackbar, snackbarMessage, snackbarSeverity,
  } = useSelector((state: RootState) => state.app)
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    if (isLogged && !isLoading) {
      dispatch(getUserPerimeters())
    }
  }, [isLogged, isLoading])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0) {
      dispatch(getRoles())

      if (!appPermissions.includes(ACCESS_PERM)) {
        setAccessDenied(true)
      }
    }
  }, [isLogged, appPermissions])

  if (!isLoading && !isLogged) return null

  return (
    <Suspense fallback={<Loader />}>
      <Router history={history}>
        <TopBar
          innerComponent={<TabList tabs={navTabElements} />}
          hasAccess={!isLoading && !accessDenied}
        />
        {(isLoading || !isLogged) && <div id="app"><Loader message={terms.Common.loading} /></div>}
        {(!isLoading && isLogged) && (accessDenied ? <AccessDenied /> : (
          <div id="app">
            <Switch>
              {navRoutes.map(route => (
                <Route exact component={route.component} path={route.path} key={route.path} />
              ))}
              <Redirect to={terms.Routes.zones.path} />
            </Switch>
          </div>
        ))}
        <AppSnackbar
          displaySnackbar={displaySnackbar}
          message={snackbarMessage}
          severity={snackbarSeverity}
          handleClose={() => dispatch(hideSnackbar())}
        />
      </Router>
    </Suspense>
  )
}
