/* eslint-disable camelcase */
import { RootState } from 'reducers/store'
import fullOsmTheme from 'assets/mapstyles/full.json'
import SettingsIcon from '@mui/icons-material/Settings'
import { Button } from '@mui/material'
import {
  MutableRefObject, ReactElement, Ref, forwardRef, useEffect, useImperativeHandle, useRef, useState,
} from 'react'
import ReactMapGL, {
  MapEvent,
  MapRef, ViewportProps,
} from 'react-map-gl'
import { useDispatch, useSelector } from 'react-redux'
import Geo, { Geometry } from 'geojson'
import terms from 'common/terms'
import { MAP_LAYER_SOURCE, loadBvImages, transformRequest } from 'services/map'
import RegionLayer from 'components/Layers/RegionLayer/RegionLayer'
import BvLayer from 'components/Layers/BvLayer/BvLayer'
import PkLayer from 'components/Layers/PkLayer/PkLayer'
import FeatureClickPopup from 'components/FeatureClickPopup/FeatureClickPopup'
import TrackLayer from 'components/Layers/TrackLayer/TrackLayer'
import SectionLineLayer from 'components/Layers/SectionLineLayer/SectionLineLayer'
import { resetViewport, updateViewport } from 'reducers/map/map.reducers'
import { Zone, ZoneType } from 'reducers/zones/types'
import { getViewport } from 'services'
import history from 'utils/history'
import './dashboardMap.scss'

export type MapSelect = {
  properties: {
    id?: string
    libelle?: string
    code_ci?: string
    code_ch?: string
  },
  layer: {
    id?: string
  },
  geometry: Geometry
}

type Props = {
  zone: Zone
  hideUpdateButton?: boolean
  onSelect?: (e: MapEvent) => void
  readOnly?: boolean
}

export type MapHandle = {
  handlePrintMap: (callback: (fd: FormData) => void) => void
  handleGetRenderedBV: () => Geo.Feature[]
  handleToggleUnselectedBV: () => void
}

const MapGL = forwardRef<MapHandle, Props>(({
  zone, hideUpdateButton, onSelect, readOnly,
}: Props, ref): ReactElement => {
  const mapRef: MutableRefObject<MapRef | undefined> | undefined = useRef()
  const mapDOMRef = useRef(null)
  const dispatch = useDispatch()
  const { viewport } = useSelector((state: RootState) => state.map)
  const [openPopup, setPopup] = useState(false)
  const [popupEvent, setPopupEvent] = useState<MapEvent>()
  const [isPrinting, setIsPrinting] = useState(null)
  const [displayUnselectedBV, setDisplayUnselectedBV] = useState(true)

  const onViewportChange = (newViewport: ViewportProps) => {
    dispatch(updateViewport(newViewport))
  }

  useEffect(() => {
    const vp = getViewport(zone?.bbox, mapDOMRef?.current?.clientWidth)
    if (vp) {
      dispatch(updateViewport({ ...viewport, ...vp, zoom: vp.zoom > 20 ? 20 : vp.zoom }))
    } else {
      dispatch(resetViewport())
    }
  }, [mapRef?.current, zone?.bbox])

  const resetPopups = () => {
    setPopup(false)
  }

  const onFeatureClick = (e: MapEvent) => {
    if (e.features?.[0]?.sourceLayer === MAP_LAYER_SOURCE.pr) {
      onSelect(e)
    }
  }

  const onFeatureHover = (e: MapEvent) => {
    if (e.features?.[0]?.sourceLayer === MAP_LAYER_SOURCE.pr) {
      setPopupEvent(e)
      setPopup(true)
      return
    }
    resetPopups()
  }

  useImperativeHandle(ref, () => ({
    handlePrintMap(callback) {
      setIsPrinting(true)
      let printingProcessed = false

      mapRef.current.getMap().on('sourcedata', () => {
        if (!printingProcessed) {
          printingProcessed = true

          // Return map canvas blob as form data to callback
          mapRef.current.getMap().getCanvas().toBlob((blob: Blob) => {
            const fd = new FormData()
            const file = new File([blob], 'capture.jpg')
            fd.append('file', file)

            callback(fd)
          }, 'image/jpeg', 0.2)

          setIsPrinting(false)
        }
      })
    },
    handleGetRenderedBV: () => {
      try {
        return mapRef?.current?.getMap()?.querySourceFeatures('bv-source', {
          sourceLayer: 'cassini_pointremarquable_test',
          filter: [
            'in',
            'id',
            ...zone.components.flatMap(c => c.elements?.filter(
              point => !!point?.gaia_id,
            ).map(point => point?.gaia_id)),
          ],
        })
      } catch (e) {
        return []
      }
    },
    handleToggleUnselectedBV: () => {
      setDisplayUnselectedBV(!displayUnselectedBV)
    },
  }))

  useEffect(() => {
    const handleClickOutside = e => {
      if (mapDOMRef.current && !mapDOMRef.current.contains(e.target)) {
        resetPopups()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="map-gl" ref={mapDOMRef}>
      <ReactMapGL
        {...viewport}
        preserveDrawingBuffer
        ref={mapRef as Ref<MapRef>}
        width="100%"
        height="100%"
        minZoom={5}
        transformRequest={transformRequest}
        mapStyle={fullOsmTheme}
        onLoad={() => { loadBvImages(mapRef) }}
        onViewportChange={onViewportChange}
        onClick={onFeatureClick}
        onHover={onFeatureHover}
      >

        <RegionLayer />

        <TrackLayer />

        {zone?.kind === ZoneType.SECTIONS && <SectionLineLayer zone={zone} /> }

        <BvLayer zone={zone} hideUnselectBV={isPrinting || !displayUnselectedBV} />

        <PkLayer />

        {openPopup && popupEvent && <FeatureClickPopup event={popupEvent} onClose={() => setPopup(false)} />}

        {!hideUpdateButton && !readOnly && (
          <div className="setting">
            <Button
              className="button"
              variant="contained"
              onClick={() => history.push(`/update-zone/${zone.id}`, { id: zone.id })}
            >
              <SettingsIcon />
              <span>
                {terms.Dashboard.Map.Settings.labelButton}
              </span>
            </Button>
          </div>
        )}
      </ReactMapGL>
    </div>
  )
})

MapGL.defaultProps = {
  hideUpdateButton: false,
  onSelect: () => { /* to implement */ },
  readOnly: false,
}

MapGL.displayName = 'MapGL'

export default MapGL
