export enum SnackbarSeverity {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export type AppState = {
  displaySnackbar: boolean
  snackbarMessage: string
  snackbarSeverity: SnackbarSeverity
  roles: string[]
}
