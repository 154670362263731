import {
  FormGroup, FormLabel, Select, MenuItem, OutlinedInput, FormControlLabel, Checkbox,
} from '@mui/material'
import terms from 'common/terms'
import CustomButton from 'components/CustomButton/CustomButton'
import { ReactElement, useEffect, useState } from 'react'
import { ParamsCreateZone, ZonesTypes } from 'reducers/zones/types'
import CustomModal from 'components/CustomModal/CustomModal'
import history from 'utils/history'
import { isAdmin } from 'utils'
import './zoneModal.scss'

const menuSelect = [
  {
    value: ZonesTypes.points,
    label: terms.Zones.ZonesTypes.points,
  },
  {
    value: ZonesTypes.segments,
    label: terms.Zones.ZonesTypes.segments,
  },
]

interface CreateZoneModalProps {
  onModal: boolean;
  handleClose: () => void;
  handleSubmit: (data: ParamsCreateZone) => { payload: { id: string }};
}

const CreateZoneModal = ({
  onModal, handleClose, handleSubmit,
}: CreateZoneModalProps): ReactElement => {
  const [zoneTitle, setZoneTitle] = useState<string>('')
  const [zoneDescription, setZoneDescription] = useState<string>('')
  const [zoneType, setZoneType] = useState<string>(ZonesTypes.segments)
  const [zoneDefault, setZoneDefault] = useState<boolean>(false)

  useEffect(() => {
    setZoneTitle('')
    setZoneDescription('')
    setZoneType(ZonesTypes.segments)
    setZoneDefault(false)
  }, [onModal])

  const handleSave = async () => {
    const response = await handleSubmit({
      name: zoneTitle,
      description: zoneDescription,
      default: zoneDefault,
      kind: zoneType,
    })

    const id = response?.payload?.id

    if (id) {
      history.push(`/update-zone/${id}`, { id })
    }
    handleClose()
  }

  return (
    <CustomModal open={onModal} handleClose={handleClose} title={terms.Zones.CreateZone.title} className="modal-zone">
      <FormGroup className="modal-zone-form-group">
        <FormLabel className="modal-zone-form-group-label" required>
          {terms.Zones.CreateZone.labels.name}
        </FormLabel>
        <OutlinedInput
          value={zoneTitle}
          onChange={event => setZoneTitle(event.target.value)}
        />
      </FormGroup>
      <FormGroup className="modal-zone-form-group">
        <FormLabel className="modal-zone-form-group-label">
          {terms.Zones.CreateZone.labels.description}
        </FormLabel>
        <OutlinedInput
          aria-label="minimum height"
          multiline
          minRows={8}
          maxRows={8}
          value={zoneDescription}
          onChange={event => setZoneDescription(event.target.value)}
        />
      </FormGroup>
      <FormGroup className="modal-zone-form-group">
        <FormLabel className="modal-zone-form-group-label" required>
          {terms.Zones.CreateZone.labels.type}
        </FormLabel>
        <Select
          value={zoneType}
          onChange={event => setZoneType(event.target.value)}
        >
          {menuSelect.map((item: {value: string, label: string}) => (
            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
      </FormGroup>
      {isAdmin() && (
        <FormGroup>
          <FormControlLabel
            className="modal-zone-checkbox"
            control={(
              <Checkbox
                checked={zoneDefault}
                onChange={() => setZoneDefault(!zoneDefault)}
              />
          )}
            label={terms.Zones.CreateZone.defaultZone}
          />
        </FormGroup>
      )}
      <CustomButton
        disabled={(zoneTitle === '') || (zoneType === '')}
        className="modal-zone-button"
        title={terms.Zones.CreateZone.buttonValidate}
        handleClick={handleSave}
        variant="primary"
      />
    </CustomModal>
  )
}

export default CreateZoneModal
