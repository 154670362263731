import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { initTranslation } from '@osrdata/app_core/dist/translation'
import { SentryConfigured } from '@osrdata/app_core'
import { store, persistor } from './reducers/store'
import 'assets/css/constants.scss'
import App from './App'

SentryConfigured()
initTranslation()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
