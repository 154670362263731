/* eslint-disable dot-notation */
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ExitToApp } from '@mui/icons-material'
import { Board } from 'reducers/boards/types'
import terms from 'common/terms'
import VerifiedIcon from '@mui/icons-material/Verified'
import { addBoardToOpenBoards } from 'reducers/boards/boards.reducers'
import { dateFormatFr } from 'pages/zones/utils'
import FavoriteIcon from 'components/FavoriteIcon/FavoriteIcon'
import DropDown from 'components/DropDown/DropDown'
import PopOver from 'components/PopOver/PopOver'
import MenuDropdown from 'components/MenuDropdown/MenuDropdown'

import './DashboardCard.scss'
import { RootState } from 'reducers/store'

interface Props {
  board: Board;
  zoneId: string;
  handleClickFavorite: () => void;
  handleClickCopy: () => void;
  handleClickModify: () => void;
  handleClickDelete: () => void;
}

const DashBoardCard = ({
  board,
  zoneId,
  handleClickFavorite,
  handleClickCopy,
  handleClickModify,
  handleClickDelete,
}: Props): ReactElement => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { list } = useSelector((state: RootState) => state.zones)
  const currentZone = list.find(zone => zone.id === zoneId)

  const dropdownActions = [
    {
      label: board.favorite ? terms.Zones.Dropdown.favoriteRemove : terms.Zones.Dropdown.favoriteAdd,
      handleClick: handleClickFavorite,
    },
    {
      label: terms.Zones.Dropdown.duplicate,
      handleClick: handleClickCopy,
    },
    {
      label: terms.Zones.Dropdown.edit,
      handleClick: handleClickModify,
    },
    {
      label: terms.Zones.Dropdown.delete,
      handleClick: handleClickDelete,
    },
  ]

  const displayedDropdownActions = dropdownActions.filter(action => {
    if (!currentZone?.can_modify && !currentZone?.can_administrate) {
      return [
        terms.Zones.Dropdown.favoriteAdd,
      ].includes(action.label)
    }
    return true
  })

  const handleAddBoardToOpenBoards = (newBoard: Board) => () => {
    dispatch(addBoardToOpenBoards({ zoneId, boardId: newBoard.id }))
  }

  return (
    <div className="dashboard-card">
      <div className="header">
        <div className="first-section">
          <PopOver
            popContent={board.name}
          >
            <h2>{board.name}</h2>
          </PopOver>
          {board.default && (
          <VerifiedIcon
            className="verified-icon"
          />
          )}
          <FavoriteIcon
            isFavorite={board.favorite}
            handleClick={handleClickFavorite}
          />
        </div>
        <DropDown
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        >
          <MenuDropdown actions={displayedDropdownActions} handleClose={() => setAnchorEl(null)} />
        </DropDown>
      </div>
      <div className="body">
        <p className="description">{board.description}</p>
        <span className="modify-date">
          {`${terms.Zones.Card.lastModified} ${dateFormatFr(new Date(board.last_modified))}`}
        </span>
        <div className="button">
          <Link
            to={`/dashboards/${board.id}?zone_id=${zoneId}`}
            className="button-item"
            onClick={handleAddBoardToOpenBoards(board)}
          >
            <ExitToApp className="button-icon" />
            <span>
              {terms.Dashboard.Card.accessBoard}
            </span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default DashBoardCard
