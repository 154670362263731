import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import cloneDeep from 'lodash/cloneDeep'
import DeleteIcon from '@mui/icons-material/Delete'
import { v4 as uuidv4 } from 'uuid'
import terms from 'common/terms'
import { Point } from 'reducers/zones/types'
import { WrapperHandle, UpdateTypeProps } from '../types'
import PRInput from '../Input'
import AddButton from '../AddButton'
import './style.scss'

const PointWrapper = forwardRef<WrapperHandle, UpdateTypeProps>(({ zone, onChange }, ref) => {
  const [points, setPoints] = useState<Point[]>([{ id: uuidv4(), label: '', selected: true }])
  const [displayErrors, setDisplayErrors] = useState(false)

  useEffect(() => {
    if (zone) {
      if (!zone?.components || !zone?.components[0]?.elements?.length) {
        setPoints([{ id: uuidv4(), label: '', selected: true }])
      } else {
        setPoints(zone?.components[0]?.elements?.map(
          (point, i) => ({
            id: uuidv4(),
            ...point,
            selected: i === 0,
          }),
        ))
      }
    }
  }, [zone])

  // Notify parent when global data changed
  useEffect(() => {
    onChange([{ elements: points }])
  }, [points])

  // Those functions are called by using ref in parent component
  useImperativeHandle(ref, () => ({
    // Override the currently selected point with map selection
    // This point is selected by handleFocus or handleAddPr methods below
    handleSelect(selectedPoint: Point) {
      const copy = cloneDeep(points)
      const selectedIndex = copy.findIndex(pre => pre.selected)

      if (selectedIndex === -1 || !selectedPoint) return

      copy[selectedIndex] = { ...copy[selectedIndex], ...selectedPoint }
      setPoints(copy)
    },
    handleErrors(hasError) {
      setDisplayErrors(hasError)
    },
  }))

  // Add a new empty point, selected by default, ready to be override by a map selecton
  const handleAddPr = () => {
    const copy = cloneDeep(points)
    setPoints([...copy.map(pre => {
      pre.selected = false
      return pre
    }), { label: '', id: uuidv4(), selected: true }])
  }

  const handleDeletePr = (idToDelete: string) => () => {
    setPoints(points.filter(pr => pr.id !== idToDelete))
  }

  // Define the currently selected point
  const handleFocus = (inputId: string) => () => {
    setPoints(points.map(pre => {
      pre.selected = pre.id === inputId
      return pre
    }))
  }

  return (
    <>
      <div className="points-wrapper">
        <div className="inputs">
          {points?.map(input => (
            <div key={`point-input-${input.id}`}>
              <PRInput
                value={input.label}
                hasError={displayErrors && !input.label}
                isSelected={input.selected}
                isFirst
                isLast
                placeholder={terms.UpdateZone.panel.inputPointPlaceholder}
                onFocus={handleFocus(input.id)}
              />
              {points.length > 1 && <DeleteIcon onClick={handleDeletePr(input.id)} />}
            </div>
          ))}
        </div>
      </div>
      <AddButton
        disabled={!points?.[0].label}
        buttonLabel={terms.UpdateZone.panel.addPointButtonLabel}
        warningLabel={terms.UpdateZone.panel.addPrWarning}
        onClick={handleAddPr}
      />
    </>
  )
})

PointWrapper.defaultProps = {
  zone: undefined,
}

PointWrapper.displayName = 'PointWrapper'
export default PointWrapper
