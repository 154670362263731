import { ReactElement, useEffect, useState } from 'react'
import terms from 'common/terms'
import CustomModal from 'components/CustomModal/CustomModal'
import { FormGroup, FormLabel, OutlinedInput } from '@mui/material'
import CustomButton from 'components/CustomButton/CustomButton'
import { Board } from 'reducers/boards/types'

import './modals.scss'

interface Props {
  onModal: boolean;
  handleClose: () => void;
  selectedBoard: Board | null;
  handleModify: (newName: string, newDescription: string) => void;
}

const DashboardUpdateModal = ({
  onModal, handleClose, selectedBoard, handleModify,
}: Props): ReactElement => {
  const [boardTitle, setBoardTitle] = useState<string>('')
  const [boardDescription, setBoardDescription] = useState<string>('')

  useEffect(() => {
    setBoardTitle(selectedBoard?.name)
    setBoardDescription(selectedBoard?.description)
  }, [onModal])

  const handleClick = () => {
    handleModify(boardTitle, boardDescription)
    handleClose()
  }

  return (
    <CustomModal
      variant="secondary"
      open={onModal}
      handleClose={handleClose}
      title={terms.Dashboard.ModifyBoard.title}
      className="modal-board"
    >
      <FormGroup className="form-group">
        <FormLabel className="label" required>
          {terms.Dashboard.ModifyBoard.labels.name}
        </FormLabel>
        <OutlinedInput
          value={boardTitle}
          onChange={event => setBoardTitle(event.target.value)}
        />
      </FormGroup>
      <FormGroup className="form-group">
        <FormLabel className="label">
          {terms.Dashboard.ModifyBoard.labels.description}
        </FormLabel>
        <OutlinedInput
          aria-label="minimum height"
          multiline
          minRows={8}
          maxRows={8}
          value={boardDescription}
          onChange={event => setBoardDescription(event.target.value)}
        />
      </FormGroup>
      <CustomButton
        title={terms.Dashboard.ModifyBoard.buttonModify}
        handleClick={handleClick}
        className="button"
        variant="secondary"
      />
    </CustomModal>
  )
}

export default DashboardUpdateModal
