import { ReactElement } from 'react'

import { ReactComponent as WarningTriangle } from 'assets/icons/warning.svg'

import { terms } from 'common/terms'
import SimpleButton from 'components/SimpleButton/SimpleButton'
import './AccessDenied.scss'

export default function AccessDenied(): ReactElement {
  return (
    <div
      id="access-denied"
    >
      <div className="warning-box">
        <WarningTriangle className="warning-icon" />
        <span className="main-message">
          {terms.Error.forbidden.appTitle}
        </span>
        <br />
        {terms.Error.forbidden.appSubtitle}
      </div>
      <div className="button-wrapper">
        <SimpleButton
          title={terms.Error.forbidden.exitButton}
          onClick={() => window.location.replace('https://dgexsol.fr')}
        />
      </div>
    </div>
  )
}
