/* eslint-disable camelcase */
import { Feature } from 'geojson'
import { FilterSettings } from 'reducers/types'

export type Group = {
  group: string,
  group_name: string,
  permission: string
}

export type GroupFormatted = {
  group: string,
  group_name: string,
  permission: string,
  check: boolean
}

export enum ZoneType {
  SECTIONS = 'segments',
  PRSET = 'points'
}

export type Point = {
  id?: string
  gaia_id?: string
  code_CI?: string
  code_CH?: string
  label?: string
  intermediate?: boolean
  selected?: boolean
}

export type Section = {
  id?: string
  label?: string
  isNew?: boolean
  elements: Point[]
}

export type Zone = {
  id: string,
  name: string,
  description: string,
  thumbnail_file: string | null,
  favorite: boolean,
  can_modify: boolean,
  can_administrate: boolean,
  owner: string,
  default: boolean,
  groups: Group[],
  creation_date: string,
  last_modified: string,
  kind: ZoneType,
  bbox: number[],
  components?: Section[]
  tracks?: Feature[]
}

export type ZonesState = {
  list: Zone[],
  filterSettings: FilterSettings,
}

export enum ZonesTypes {
  segments = 'segments',
  points = 'points',
}

export type ParamsCreateZone = {
  name: string,
  description: string,
  kind: string,
  default: boolean,
}

export type ParamsModifyZone = {
  id?: string,
  zoneId: string,
  name?: string,
  thumbnail_file?: FormData,
  description?: string,
  favorite?: boolean,
  groups?: {
    group: string
    permission: string | null
  }[],
  bbox?: number[],
  components?: Section[]
}
