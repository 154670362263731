import { ChangeEvent, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import './customMultiText.scss'

type Props = {
  selected: string[]
  handleChange: (value: string, toReplace?: string) => void
}

const CustomMultiText = ({ selected, handleChange }: Props) => {
  const [values, setValues] = useState<{value: string, id: string}[]>(
    selected ? selected?.map(value => ({ value, id: uuidv4() })) : [{ value: '', id: uuidv4() }],
  )

  useEffect(() => {
    if (!selected) setValues([{ value: '', id: uuidv4() }])
  }, [selected])

  const add = () => {
    setValues([...values, { value: '', id: uuidv4() }])
  }

  const remove = (id: string) => () => {
    const value = values.find(tr => tr.id === id)?.value
    const valuesFiltered = values.filter(tr => tr.id !== id)
    if (value) handleChange(value)
    setValues(valuesFiltered)
  }

  const handleInputChange = (id: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = values.find(tr => tr.id === id)?.value
    const valuesFiltered = values.map(tr => {
      if (tr.id === id) {
        tr.value = event.target.value
      }
      return tr
    })
    setValues(valuesFiltered)
    handleChange(event.target.value, value)
  }

  return (
    <div className="multi_input_range">
      <AddIcon className="range-action" onClick={add} />
      <div className="inputs">
        {values?.map(({ id, value }) => (
          <div className="range" key={id}>
            <div className="input">
              <input type="text" defaultValue={value} onChange={handleInputChange(id)} />
            </div>
            <RemoveIcon className="range-action" onClick={remove(id)} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default CustomMultiText
