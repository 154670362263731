import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { FilterSettings, FilterSortBy, FilterSortDirection } from 'reducers/types'
import { AvailableWidgets, BoardsState, Widget } from './types'
import {
  deleteBoard,
  getBoards,
  postBoard,
  getBoard,
  postWidget,
  deleteWidget,
  patchWidget,
  patchBoard,
  copyBoard,
  getAvailableWidgets,
} from './boards.thunk'

const initFilterSettings: FilterSettings = {
  sortBy: FilterSortBy.LAST_MODIFIED,
  sortDirection: FilterSortDirection.DESC,
  search: '',
  favorites: false,
  defaults: false,
}

const initialState: BoardsState = {
  filterSettings: initFilterSettings,
  list: [],
  availableWidgets: {},
  opened: {},
  loading: 'idle',
  loadingList: 'idle',
}

export const boards = createSlice({
  name: 'boards',
  initialState,
  reducers: {
    setFilterSettings: (state, action: PayloadAction<FilterSettings>) => {
      state.filterSettings = action.payload
    },
    addBoardToOpenBoards: (state, action: PayloadAction<{zoneId: string, boardId: string}>) => {
      const { zoneId, boardId } = action.payload
      if (!state.opened[zoneId]) {
        state.opened[zoneId] = []
      }
      if (!state.opened[zoneId].find(id => id === boardId)) {
        state.opened[zoneId].push(boardId)
      }
    },
    removeBoardFromOpenBoards: (state, action: PayloadAction<{zoneId: string, boardId: string}>) => {
      const { zoneId, boardId } = action.payload
      state.opened[zoneId] = state.opened[zoneId].filter(id => id !== boardId)
    },
    removeAllBoardsFromOpenBoards: (state, action: PayloadAction<{zoneId: string}>) => {
      state.opened[action.payload.zoneId] = []
    },
    addFilterParamsToWidget: (
      state,
      action: PayloadAction<{boardId: string, widgetId: string, params: {slug: string, value: string}[]}>,
    ) => {
      const { boardId, widgetId, params } = action.payload
      const boardIndex = state.list.findIndex(board => board.id === boardId)
      const widgetIndex = state.list[boardIndex].widgets.findIndex(widget => widget.id === widgetId)

      if (widgetIndex !== -1 && boardIndex !== -1) {
        state.list[boardIndex].widgets[widgetIndex].filterParams = params.filter(param => param.value)
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(getBoard.pending, state => { state.loading = 'pending' })
    builder.addCase(getBoard.rejected, state => { state.loading = 'failed' })
    builder.addCase(getBoard.fulfilled, (state, { payload }) => {
      const { zone_id: zoneId, id } = payload
      const index = state.list.findIndex(board => board.id === id)

      if (index !== -1) {
        state.list[index] = payload
      } else {
        state.list.push(payload)
      }

      if (zoneId) {
        if (!state.opened[zoneId]) {
          state.opened[zoneId] = []
        }
        if (!state.opened[zoneId].find(boardId => boardId === id)) {
          state.opened[zoneId].push(id)
        }
      }

      state.loading = 'succeeded'
    })
    builder.addCase(getBoards.pending, state => { state.loadingList = 'pending' })
    builder.addCase(getBoards.rejected, state => { state.loadingList = 'failed' })
    builder.addCase(getBoards.fulfilled, (state, { payload }) => {
      state.list = payload.results
      state.loadingList = 'succeeded'
    })
    builder.addCase(postBoard.fulfilled, (state, { payload }) => {
      state.list = [payload, ...state.list]
    })
    builder.addCase(deleteBoard.fulfilled, (state, action) => {
      state.list = state.list.filter(board => board.id !== action.meta.arg)

      // Remove board from opened boards
      Object.keys(state.opened).forEach(zoneId => {
        state.opened[zoneId] = state.opened[zoneId].filter(boardId => boardId !== action.meta.arg)
      })
    })
    builder.addCase(postWidget.fulfilled, (state, { meta, payload }) => {
      const boardIndex = state.list.findIndex(board => board.id === meta.arg.boardId)
      state.list?.[boardIndex].widgets.push(payload)
    })
    builder.addCase(patchWidget.fulfilled, (state, { meta, payload }) => {
      const { boardId, widgetId } = meta.arg
      const boardIndex = state.list.findIndex(board => board.id === boardId)
      const widgetIndex = state.list[boardIndex].widgets.findIndex(widget => widget.id === widgetId)

      if (widgetIndex !== -1 && boardIndex !== -1) {
        state.list[boardIndex].widgets[widgetIndex] = payload
      }
    })
    builder.addCase(deleteWidget.fulfilled, (state, { meta }) => {
      const { boardId, widgetId } = meta.arg
      state.list = state.list.map(board => {
        if (board.id === boardId) {
          board.widgets = board.widgets.filter(widget => widget.id !== widgetId)
        }
        return board
      })
    })
    builder.addCase(patchBoard.fulfilled, (state, { payload }) => {
      state.list = state.list.map(board => {
        if (board.id === payload.id) {
          return payload
        }
        return board
      })
    })
    builder.addCase(copyBoard.fulfilled, (state, { payload }) => {
      state.list = [payload, ...state.list]
    })
    builder.addCase(getAvailableWidgets.fulfilled, (state, { payload }) => {
      state.availableWidgets = payload.reduce((acc: AvailableWidgets, cur: Partial<Widget>) => {
        if (!acc[cur.metric_slug]) {
          acc[cur.metric_slug] = {
            metric_slug: cur.metric_slug,
            name: cur.name.split(' - ')[0],
            formats: [],
            info: cur.info,
          }
        }
        acc[cur.metric_slug].formats.push(cur)
        return acc
      }, {})
    })
  },
})

export const {
  setFilterSettings,
  addBoardToOpenBoards,
  removeBoardFromOpenBoards,
  removeAllBoardsFromOpenBoards,
  addFilterParamsToWidget,
} = boards.actions

export default boards.reducer
