import { ReactElement } from 'react'
import './CardWrapper.scss'

interface Props {
  children: ReactElement[] | ReactElement;
}

function CardWrapper({ children }: Props): ReactElement {
  return (
    <div className="card-list-item">
      {children}
    </div>
  )
}

export default CardWrapper
