import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { FilterSettings, FilterSortBy, FilterSortDirection } from 'reducers/types'
import { ZonesState } from './types'
import {
  copyZone,
  deleteZone, getZones, patchZones, postZones, getZone,
} from './zones.thunks'

const initFilterSettings: FilterSettings = {
  sortBy: FilterSortBy.LAST_MODIFIED,
  sortDirection: FilterSortDirection.DESC,
  search: '',
  favorites: false,
  defaults: false,
}

const initialState: ZonesState = {
  filterSettings: initFilterSettings,
  list: [],
}

export const zones = createSlice({
  name: 'zones',
  initialState,
  reducers: {
    setFilterSettings: (state, action: PayloadAction<FilterSettings>) => {
      state.filterSettings = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(getZones.fulfilled, (state, { payload }) => {
      state.list = payload.results
    })
    builder.addCase(getZone.fulfilled, (state, { payload }) => {
      if (state.list.find(zone => zone.id === payload.id)) {
        state.list = state.list.map(zone => (zone.id === payload.id ? payload : zone))
      } else {
        state.list.push(payload)
      }
    })
    builder.addCase(postZones.fulfilled, (state, { payload }) => {
      state.list.push(payload)
    })
    builder.addCase(deleteZone.fulfilled, (state, action) => {
      state.list = state.list.filter(zone => zone.id !== action.meta.arg)
    })
    builder.addCase(copyZone.fulfilled, (state, { payload }) => {
      state.list = [payload, ...state.list]
    })
    builder.addCase(patchZones.fulfilled, (state, { payload }) => {
      state.list = state.list.map(zone => {
        if (zone.id === payload.id) {
          return payload
        }
        return zone
      })
    })
  },
})

export const {
  setFilterSettings,
} = zones.actions

export default zones.reducer
