/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import { get } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'

const getRoles = createAsyncThunk(
  'app/getRoles',
  async (_, thunkApi) => {
    try {
      const response = await get('/usage_reseau/user_permissions/')
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  getRoles,
}
