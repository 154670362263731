import { ChangeEvent, ReactElement } from 'react'
import { Search, ArrowDownward, ArrowUpward } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import terms from 'common/terms'
import { FilterSettings, FilterSortBy, FilterSortDirection } from 'reducers/types'

import './FilterHeader.scss'
import CustomButton from 'components/CustomButton/CustomButton'
import Loader from 'components/Loader/Loader'

interface Props {
  handleDefaultClick: () => void
  handleFavoriteClick: () => void
  allElementsLabel: string
  allElementsCount: number
  handleSearchChange: (e: ChangeEvent<HTMLInputElement>) => void
  filterSettings: FilterSettings
  handleChangeSortType: () => void
  handleChangeSortDirection: () => void
  buttonLabel: string
  handleAddClick: () => void
  variant: 'filter' | 'primary' | 'secondary'
  isLoading?: boolean
  disableAction?: boolean
}

const FilterHeader = ({
  handleDefaultClick,
  handleFavoriteClick,
  allElementsLabel,
  allElementsCount,
  handleSearchChange,
  filterSettings,
  handleChangeSortType,
  handleChangeSortDirection,
  buttonLabel,
  handleAddClick,
  variant,
  isLoading,
  disableAction,
}: Props): ReactElement => (
  <div className="filter-header">
    <div className="filter-header-buttons">
      <div className="filter-header-button-favorite">
        <button type="button" onClick={handleDefaultClick} className={filterSettings.defaults ? 'selected' : ''}>
          {terms.Components.FilterHeader.default}
        </button>
        <button type="button" onClick={handleFavoriteClick} className={filterSettings.favorites ? 'selected' : ''}>
          {terms.Components.FilterHeader.favorites}
        </button>
      </div>
      <div className="filter-header-search-wrapper">
        <div className="search-bar">
          <Search />
          <input
            type="text"
            placeholder={terms.Common.search}
            onChange={handleSearchChange}
            value={filterSettings.search}
          />
        </div>
        <div className="filter-header-add-zone">
          <CustomButton
            disabled={disableAction}
            title={buttonLabel}
            handleClick={handleAddClick}
            variant={variant}
            icon={<AddIcon />}
          />
        </div>
      </div>
    </div>
    <div className="filter-header-infos">
      <div className="filter-header-infos-all">
        <span className="label">{allElementsLabel}</span>
        <span className="count">{`(${allElementsCount})`}</span>
      </div>
      {isLoading && <Loader />}
      <div className="filter-header-infos-sort">
        <span className="label">{terms.Components.FilterHeader.sortBy}</span>
        <button className="sort-type" type="button" onClick={handleChangeSortType}>
          {filterSettings.sortBy === FilterSortBy.LAST_MODIFIED
            ? terms.Components.FilterHeader.sortOptions.modificationDate
            : terms.Components.FilterHeader.sortOptions.name}
        </button>
        <button className="sort-direction" type="button" onClick={handleChangeSortDirection}>
          {filterSettings.sortDirection === FilterSortDirection.ASC ? <ArrowUpward /> : <ArrowDownward />}
        </button>
      </div>
    </div>
  </div>
)

FilterHeader.defaultProps = {
  isLoading: false,
  disableAction: false,
}

export default FilterHeader
