import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState, SnackbarSeverity } from './types'
import { getRoles } from './app.thunk'

const initialState: AppState = {
  displaySnackbar: false,
  snackbarMessage: '',
  snackbarSeverity: SnackbarSeverity.SUCCESS,
  roles: [],
}

export const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSnackbar: (state, action: PayloadAction<{ message: string, severity: SnackbarSeverity }>) => {
      state.displaySnackbar = true
      state.snackbarMessage = action.payload.message
      state.snackbarSeverity = action.payload.severity
    },
    hideSnackbar: state => {
      state.displaySnackbar = false
      state.snackbarMessage = ''
      state.snackbarSeverity = SnackbarSeverity.SUCCESS
    },
  },
  extraReducers: builder => {
    builder.addCase(getRoles.fulfilled, (state, { payload }) => {
      state.roles = payload
    })
  },
})

export const {
  setSnackbar,
  hideSnackbar,
} = app.actions

export default app.reducer
