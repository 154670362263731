import {
  ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState,
} from 'react'
import { v4 as uuidv4 } from 'uuid'
import cloneDeep from 'lodash/cloneDeep'
import DeleteIcon from '@mui/icons-material/Delete'
import AddBoxIcon from '@mui/icons-material/AddBox'
import terms from 'common/terms'
import { getSectionLabelValue } from 'services'
import { Point, Section } from 'reducers/zones/types'
import { UpdateTypeProps, WrapperHandle } from '../types'
import PRInput from '../Input'
import AddButton from '../AddButton'
import './style.scss'

const SectionWrapper = forwardRef<WrapperHandle, UpdateTypeProps>(({ zone, onChange }, ref) => {
  const getEmptySection = (): Section => ({
    id: uuidv4(),
    label: '',
    isNew: true,
    elements: [{
      label: '', id: uuidv4(), selected: true, intermediate: false,
    }, {
      label: '', id: uuidv4(), selected: false, intermediate: false,
    },
    ],
  })
  const [sections, setSections] = useState<Section[]>([getEmptySection()])
  const [displayErrors, setDisplayErrors] = useState(false)
  const shouldDisableButton = sections[0]?.elements.filter(({ label }) => !!label).length < 2
  const shouldEnableAddPr = (sectionId: string) => sections
    .find(({ id }) => id === sectionId)?.elements
    .filter(({ label }) => !!label).length > 1

  useEffect(() => {
    onChange(sections)
  }, [sections])

  useEffect(() => {
    if (zone && JSON.stringify(zone)) {
      if (!zone?.components || !zone?.components[0]?.elements) {
        setSections([getEmptySection()])
      } else {
        const componentsCopy = cloneDeep(zone?.components)
        setSections(componentsCopy.map(section => ({
          ...section,
          id: uuidv4(),
        })))
      }
    }
  }, [zone])

  useImperativeHandle(ref, () => ({
    handleSelect(selectedPoint: Point) {
      const copy = cloneDeep(sections)
      const current = copy.find(pre => pre.elements.find(({ selected }) => selected))

      if (!current || !selectedPoint) return

      current.elements = current.elements
        .map(point => (point.selected ? { ...point, ...selectedPoint } : point))

      if (current.isNew) {
        // set next point in section as selected and unselect current one
        const nextIndex = current.elements.findIndex(({ selected }) => selected) + 1
        if (nextIndex < current.elements.length) {
          current.elements[nextIndex].selected = true
          current.elements[nextIndex - 1].selected = false
        } else {
          current.isNew = false
        }
      }

      setSections(copy)
    },
    handleErrors(hasError: boolean) {
      setDisplayErrors(hasError)
    },
  }))

  // Return a copy of sections with all points unselected
  const getUnselectedSections = () => sections.map(section => ({
    ...section,
    elements: section.elements.map(point => ({
      ...point,
      selected: false,
    })),
  }))

  const handleFocus = (inputId: string) => () => {
    setSections(sections.map(section => ({
      ...section,
      elements: section.elements.map(point => ({
        ...point,
        selected: point.id === inputId,
      })),
    })))
  }

  const handleAddSection = () => {
    setSections([...getUnselectedSections(), getEmptySection()])
  }

  const handleDeleteSection = (sectionId: string) => () => {
    setSections(cloneDeep(sections).filter(({ id }) => id !== sectionId))
  }

  const handleAddIntermediatePr = (sectionId: string) => () => {
    const copy = cloneDeep(getUnselectedSections())
    const section = copy.find(({ id }) => id === sectionId)
    section.elements.splice(section.elements.length - 1, 0, {
      label: '', id: uuidv4(), selected: true, intermediate: false,
    })
    setSections(copy)
  }

  const handleDeletePr = (sectionId: string, inputId: string) => () => {
    const copy = cloneDeep(sections)
    const section = copy.find(({ id }) => id === sectionId)
    section.elements = copy.find(({ id }) => id === sectionId).elements.filter(({ id }) => id !== inputId)
    setSections(copy)
  }

  const handleSectionLabelChange = (sectionId: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const copy = cloneDeep(sections)
    copy.find(({ id }) => id === sectionId).label = event.target.value
    setSections(copy)
  }

  let inputs = []

  return (
    <>
      <div className="sections-wrapper">
        {sections.map(section => (
          <div className="section" key={section.id}>
            <div className="label">
              <input onChange={handleSectionLabelChange(section.id)} value={getSectionLabelValue(section)} />
              {sections.length > 1 && <DeleteIcon onClick={handleDeleteSection(section.id)} />}
            </div>
            <div className="inputs">
              {section.elements.map((input, i) => {
                if (input.intermediate) {
                  inputs.push(input)
                  return null
                }

                inputs = []
                return (
                  <PRInput
                    key={`pr-input-${input.id}`}
                    value={input.label}
                    hasError={displayErrors && !input.label}
                    isSelected={input.selected}
                    isFirst={i === 0}
                    isLast={i === section.elements.length - 1}
                    isDeletable={section.elements.filter(el => !el?.intermediate).length > 2}
                    intermediates={inputs}
                    placeholder={terms.UpdateZone.panel.inputSectionPlaceholder}
                    onFocus={handleFocus(input.id)}
                    onDelete={handleDeletePr(section.id, input.id)}
                  />
                )
              })}
            </div>
            {shouldEnableAddPr(section.id) && (
              <div
                role="button"
                tabIndex={0}
                className="add-intermediate-pr"
                onClick={handleAddIntermediatePr(section.id)}
              >
                <AddBoxIcon />
                {terms.UpdateZone.panel.addIntermediatePr}
              </div>
            )}
          </div>
        ))}
      </div>
      <AddButton
        disabled={shouldDisableButton}
        buttonLabel={terms.UpdateZone.panel.addSectionButtonLabel}
        warningLabel={terms.UpdateZone.panel.addSectionWarning}
        onClick={handleAddSection}
      />
    </>
  )
})

SectionWrapper.defaultProps = {
  zone: undefined,
}
SectionWrapper.displayName = 'SectionWrapper'
export default SectionWrapper
