/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  ChangeEvent, FocusEvent, ReactElement, useEffect, useRef, useState,
} from 'react'
import { Checkbox } from '@mui/material'
import { ReactComponent as UnfoldIcon } from 'assets/icons/unfoldMore.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import terms from 'common/terms'
import stringUtils from 'utils/strings'
import { useIsVisible } from 'utils/hooks'
import './customSelectCheck.scss'

interface Props {
  list: string[],
  selected: string[]
  handleclick: (value: string) => void,
}

// TODO : Virtualize the list before displaying it

const CustomSelectCheck = ({
  list,
  selected = [],
  handleclick,
}: Props): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null)
  const isVisible = useIsVisible(inputRef)
  const resultRef = useRef<HTMLDivElement>(null)
  const [displaySearch, setDisplaySearch] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')

  const updateViewport = () => {
    const { clientHeight } = document.documentElement
    const input = inputRef?.current
    const result = resultRef?.current

    if (!isVisible || !input || !result) {
      setDisplaySearch(false)
      return
    }

    const {
      top, left, width, height,
    } = input.getBoundingClientRect()

    result.style.setProperty('top', `${top + height}px`)
    result.style.setProperty('left', `${left}px`)
    result.style.setProperty('width', `${width}px`)
    result.style.setProperty('opacity', '1')

    if (result.getBoundingClientRect().bottom > clientHeight) {
      result.style.setProperty('top', `${top - result.clientHeight}px`)

      if (result.getBoundingClientRect().bottom > clientHeight) {
        setDisplaySearch(false)
      }
    }

    if (result.getBoundingClientRect().top < 0) {
      setDisplaySearch(false)
    }
  }

  useEffect(() => {
    if (inputRef?.current && resultRef?.current && isVisible) {
      window.addEventListener('wheel', updateViewport)
      window.addEventListener('touchmove', updateViewport)
      window.addEventListener('resize', updateViewport)
    }

    return () => {
      window.removeEventListener('wheel', updateViewport)
      window.removeEventListener('touchmove', updateViewport)
      window.removeEventListener('resize', updateViewport)
    }
  }, [displaySearch, isVisible, inputRef, resultRef])

  useEffect(() => {
    if (displaySearch) {
      updateViewport()
    }
  }, [displaySearch, isVisible, inputRef, resultRef])

  const handleBlur = ({ currentTarget }: FocusEvent<HTMLInputElement>) => {
    requestAnimationFrame(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setDisplaySearch(false)
      }
    })
  }

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    updateViewport()
    setDisplaySearch(true)
    setSearchValue(target.value)
  }

  const handleSelectResult = (result: string) => () => {
    handleclick(result)
  }

  const getSanitizedSearchList = () => {
    const splitSearchValue = searchValue.split(',')
    return selected.concat(list?.filter(
      item => !selected.includes(item) && splitSearchValue.every(
        value => stringUtils.normalize(item.trim()).includes(stringUtils.normalize(value.trim())),
      ),
    ))
  }

  return (
    <div
      className="custom-select-check"
      onClick={() => setDisplaySearch(true)}
      onBlur={handleBlur}
      ref={inputRef}
    >
      <div className="input">
        <SearchIcon />
        <input
          type="text"
          value={searchValue}
          onChange={handleSearch}
          placeholder={terms.Widgets.filterModal.labels.choose}
        />
        <UnfoldIcon />
      </div>

      {displaySearch && (
        <div className="results" ref={resultRef}>
          {getSanitizedSearchList().map(result => (
            <button
              key={result}
              type="button"
              onClick={handleSelectResult(result)}
              className={`${selected?.includes(result) ? 'selected' : ''}`}
            >
              <Checkbox checked={!!selected?.includes(result)} />
              <p>{result}</p>
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default CustomSelectCheck
