/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ExitToApp } from '@mui/icons-material'
import { Zone } from 'reducers/zones/types'
import terms from 'common/terms'
import VerifiedIcon from '@mui/icons-material/Verified'
import FavoriteIcon from 'components/FavoriteIcon/FavoriteIcon'
import { Link } from 'react-router-dom'
import { RootState } from 'reducers/store'
import DropDown from 'components/DropDown/DropDown'
import PopOver from 'components/PopOver/PopOver'
import MenuDropdown from 'components/MenuDropdown/MenuDropdown'
import { dateFormatFr } from '../utils'

import './ZoneCard.scss'

interface Props {
  zone: Zone;
  handleClickFavorite: () => void;
  handleClickCopy: () => void;
  handleClickModify: () => void;
  handleClickDelete: () => void;
  handleClickShare: () => void;
}

const ZoneCard = ({
  zone, handleClickFavorite, handleClickCopy, handleClickModify, handleClickDelete, handleClickShare,
}: Props): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [thumbnail, setThumbnail] = useState<string>('')
  const [displayThumbnail, setDisplayThumbnail] = useState<boolean>(false)
  // TODO : Update user type in appCore to add missing account.id attribute
  // eslint-disable-next-line dot-notation
  const userId = useSelector((state: RootState) => state?.user?.account['id'])

  useEffect(() => {
    if (!zone.thumbnail_file) return

    fetch(zone.thumbnail_file, {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(url => {
        setThumbnail(url)
      })
  }, [zone?.thumbnail_file])

  const dropdownActions = [
    {
      label: zone.favorite ? terms.Zones.Dropdown.favoriteRemove : terms.Zones.Dropdown.favoriteAdd,
      handleClick: handleClickFavorite,
    },
    {
      label: terms.Zones.Dropdown.duplicate,
      handleClick: handleClickCopy,
    },
    {
      label: terms.Zones.Dropdown.share,
      handleClick: handleClickShare,
    },
    {
      label: terms.Zones.Dropdown.edit,
      handleClick: handleClickModify,
    },
    {
      label: terms.Zones.Dropdown.delete,
      handleClick: handleClickDelete,
    },
  ]

  const handleDisplayThumbnail = () => {
    setDisplayThumbnail(prev => !prev)
  }

  const displayedDropdownActions = dropdownActions.filter(action => {
    if (!zone.can_modify && !zone.can_administrate) {
      return [
        terms.Zones.Dropdown.favoriteAdd,
        terms.Zones.Dropdown.duplicate,
      ].includes(action.label)
    }
    if (zone.can_modify && !zone.can_administrate) {
      return [
        terms.Zones.Dropdown.favoriteAdd,
        terms.Zones.Dropdown.duplicate,
        terms.Zones.Dropdown.edit,
      ].includes(action.label)
    }
    return true
  })

  return (
    <div className="zone-card-container">
      <div className="zone-card-header">
        <div className="zone-card-header-first-section">
          <PopOver
            popContent={zone.name}
          >
            <h2>{zone.name}</h2>
          </PopOver>
          {zone.default && (
            <VerifiedIcon
              className="zone-card-header-first-section-verified-icon"
            />
          )}
          <FavoriteIcon
            isFavorite={zone.favorite}
            handleClick={handleClickFavorite}
          />
        </div>
        <DropDown
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        >
          <MenuDropdown actions={displayedDropdownActions} handleClose={() => setAnchorEl(null)} />
        </DropDown>
      </div>
      <div className="zone-card-body">
        <p className="description">{zone.description}</p>
        <span className="modify-date">
          {`${terms.Zones.Card.lastModified} ${dateFormatFr(new Date(zone.last_modified))}`}
        </span>
        <div className="thumbnail-wrapper">
          {thumbnail && (
            <img
              className={`thumbnail ${displayThumbnail ? ' displayed' : ''}`}
              src={thumbnail}
              alt={zone.name}
              onClick={handleDisplayThumbnail}
            />
          )}
        </div>
        <div className="zone-card-button">
          <Link
            to={`${terms.Routes.zones.path + zone.id}/`}
            className="zone-card-button-item"
          >
            <ExitToApp />
            <span>
              {terms.Zones.Card.accessZone}
            </span>
          </Link>
        </div>
      </div>
      {!!userId && zone?.owner !== userId && (
        <p className="shared">
          {`${terms.Zones.Card.sharedInfo} ${zone?.can_modify ? ' en écriture' : ' en lecture'}`}
        </p>
      )}
    </div>
  )
}

export default ZoneCard
