import { ReactElement, useEffect, useState } from 'react'
import terms from 'common/terms'
import CustomModal from 'components/CustomModal/CustomModal'
import history from 'utils/history'
import { FormGroup, FormLabel, OutlinedInput } from '@mui/material'
import CustomButton from 'components/CustomButton/CustomButton'
import { Zone, ZonesTypes } from 'reducers/zones/types'
import './zoneModal.scss'

interface Props {
  onModal: boolean;
  handleClose: () => void;
  selectedZone: Zone | null;
  handleModify: (newName: string, newDescription: string) => void;
}

const ModifyZoneModal = ({
  onModal, handleClose, selectedZone, handleModify,
}: Props): ReactElement => {
  const [zoneTitle, setZoneTitle] = useState<string>('')
  const [zoneDescription, setZoneDescription] = useState<string>('')

  useEffect(() => {
    setZoneTitle(selectedZone?.name)
    setZoneDescription(selectedZone?.description)
  }, [onModal])

  const handleClick = () => {
    handleModify(zoneTitle, zoneDescription)
    handleClose()
  }

  const handleClickAdd = () => {
    if (selectedZone?.id) {
      const { id } = selectedZone
      if (zoneTitle !== selectedZone.name || zoneDescription !== selectedZone.description) {
        // Patch changes before leaving page
        handleModify(zoneTitle, zoneDescription)
      }
      history.push(`/update-zone/${id}`, { id })
    }
    handleClose()
  }

  const buttonTitle = (type: string) => {
    if (type === ZonesTypes.points) {
      return terms.UpdateZone.modal.labelButtonPoints
    }
    return terms.UpdateZone.modal.labelButtonSegments
  }

  return (
    <CustomModal open={onModal} handleClose={handleClose} title={terms.Zones.ModifyZone.title} className="modal-zone">
      <FormGroup className="modal-zone-form-group">
        <FormLabel className="modal-zone-form-group-label" required>
          {terms.Zones.ModifyZone.labels.name}
        </FormLabel>
        <OutlinedInput
          value={zoneTitle}
          onChange={event => setZoneTitle(event.target.value)}
        />
      </FormGroup>
      <FormGroup className="modal-zone-form-group">
        <FormLabel className="modal-zone-form-group-label">
          {terms.Zones.ModifyZone.labels.description}
        </FormLabel>
        <OutlinedInput
          aria-label="minimum height"
          multiline
          minRows={8}
          maxRows={8}
          value={zoneDescription}
          onChange={event => setZoneDescription(event.target.value)}
        />
      </FormGroup>
      <CustomButton
        title={buttonTitle(selectedZone?.kind)}
        handleClick={handleClickAdd}
        className="modal-zone-button-kind"
        variant="secondary"
      />
      <CustomButton
        disabled={(zoneTitle === '')}
        title={terms.Zones.ModifyZone.buttonModify}
        handleClick={handleClick}
        className="modal-zone-button"
        variant="primary"
      />
    </CustomModal>
  )
}

export default ModifyZoneModal
