/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  ReactElement, cloneElement, useRef, useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { toPng } from 'html-to-image'
import { get } from '@osrdata/app_core/dist/requests'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ImageIcon from '@mui/icons-material/Image'
import TuneIcon from '@mui/icons-material/Tune'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ReactComponent as XLSIcon } from 'assets/icons/xls.svg'
import getWidgetParamsValue from 'services/widget'
import CustomButton from 'components/CustomButton/CustomButton'
import { Widget } from 'reducers/boards/types'
import { addFilterParamsToWidget } from 'reducers/boards/boards.reducers'
import terms from 'common/terms'
import moment from 'moment'
import FilterWidgetModal from '../Modals/WidgetFilter/FilterWidgetModal'
import './Wrapper.scss'

type Props = {
  widget: Widget
  boardId: string
  onDelete: () => void
  enablePositionAction: boolean
  readonly?: boolean
  children?: ReactElement
}

/* TODO: Colors should be retrieve from the back in the future... */
export const chartColors = [
  '#219EBC',
  '#701192',
  '#034363',
  '#DED391',
  '#AB5CC1',
  '#8ECAE6',
  '#2274A5',
  '#FCBF49',
  '#D62828',
  '#F77F00',
  '#FF9EBC',
  '#7FF192',
  '#0F4363',
  '#DFF001',
  '#AFFCC1',
  '#FFCAE6',
  '#F274A5',
  '#C0BF49',
  '#DF0E0F',
  '#077F00',
  '#219EBC',
  '#701192',
  '#034363',
  '#DED391',
  '#AB5CC1',
  '#8ECAE6',
  '#2274A5',
  '#FCBF49',
  '#D62828',
  '#F77F00',
  '#FF9EBC',
  '#7FF192',
  '#0F4363',
  '#DFF001',
  '#AFFCC1',
  '#FFCAE6',
  '#F274A5',
  '#C0BF49',
  '#DF0E0F',
  '#077F00',
  '#219EBC',
  '#701192',
  '#034363',
  '#DED391',
  '#AB5CC1',
  '#8ECAE6',
  '#2274A5',
  '#FCBF49',
  '#D62828',
  '#F77F00',
  '#FF9EBC',
  '#7FF192',
  '#0F4363',
  '#DFF001',
  '#AFFCC1',
  '#FFCAE6',
  '#F274A5',
  '#C0BF49',
  '#DF0E0F',
  '#077F00',
]

const WidgetWrapper = ({
  enablePositionAction, widget, boardId, onDelete, readonly, children,
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const actionsRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const [toggleInfo, setToggleInfo] = useState(false)
  const [modalDisplayed, setModalDisplayed] = useState(false)

  const handleSubmitFilter = (params: { slug: string, value: string }[]) => {
    dispatch(addFilterParamsToWidget({
      boardId,
      widgetId: widget.id,
      params,
    }))
  }

  const handleGetValue = getWidgetParamsValue(widget.parameters)

  const handleToggleInfo = () => {
    setToggleInfo(!toggleInfo)
  }

  const renderParamater = (label: string, value: string | string[] | number) => (
    <p>
      {`${label} : `}
      <b>{value}</b>
    </p>
  )

  const handleSaveWrapperAsPng = () => {
    const element = wrapperRef.current
    const actions = actionsRef.current
    if (element) {
      actions?.classList.add('hidden')
      toPng(element, { cacheBust: true })
        .then(dataUrl => {
          const link = document.createElement('a')
          link.download = `${widget.title}.png`
          link.href = dataUrl
          link.click()
          actions?.classList.remove('hidden')
        })
    }
  }

  const getWidgetParametersAsObject = () => {
    const parameters = {}
    widget.parameters?.forEach(param => {
      parameters[param.slug] = param.value
    })
    return parameters
  }

  const getWidgetFiltersAsObject = () => {
    const filters = {}
    widget.filterParams?.forEach(param => {
      filters[param.slug] = param.value
    })
    return filters
  }

  const handleSaveWrapperAsXls = () => {
    get(
      `/usage_reseau/metrics/${widget.metric_slug}/table-xlsx/`,
      Object.assign(getWidgetParametersAsObject(), getWidgetFiltersAsObject()),
      null,
      {
        responseType: 'blob',
      },
    ).then(response => {
      const blob = new Blob(
        [response],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      )
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${widget.title}-${moment().format('L')}.xlsx`

      link.click()
    })
  }

  return (
    <>
      <div ref={wrapperRef} className={`widget-wrapper${enablePositionAction ? ' updatable' : ''}`}>
        {!enablePositionAction && (
          <>
            <div className={`info ${!toggleInfo ? 'collapsed' : ''}`}>
              <h3 onClick={handleToggleInfo}>
                {widget.title || terms.Dashboard.Widgets.info.title}
                <ExpandMoreIcon />
              </h3>
              <div className="parameters">
                {renderParamater(terms.Dashboard.Widgets.info.params.metric, widget.name.split(' - ')[0])}
                {renderParamater(terms.Dashboard.Widgets.info.params.type, widget.name.split(' - ')[1])}
                {renderParamater(
                  terms.Dashboard.Widgets.info.params.date,
                  `${moment(handleGetValue('start_date')).format('DD/MM/YYYY')} - 
                  ${moment(handleGetValue('end_date')).format('DD/MM/YYYY')}`,
                )}
                {widget?.parameters?.filter(
                  param => !['start_date', 'end_date', 'zone_id'].includes(param.slug),
                ).map(param => (
                  renderParamater(param.name, param.value)
                ))}
              </div>
            </div>
            <div ref={actionsRef} className="actions">
              <div className="filter-action-wrapper">
                <CustomButton
                  title=""
                  icon={<TuneIcon />}
                  variant="primary"
                  handleClick={() => setModalDisplayed(true)}
                />
                {widget?.filterParams && Object.keys(widget?.filterParams).length > 0 && (
                  <span>{Object.keys(widget?.filterParams).length}</span>
                )}
              </div>
              <CustomButton
                title=""
                icon={<ImageIcon />}
                variant="black"
                handleClick={handleSaveWrapperAsPng}
              />
              <CustomButton
                title=""
                icon={<XLSIcon />}
                variant="black"
                handleClick={handleSaveWrapperAsXls}
              />
              {!readonly && (
                <CustomButton
                  title=""
                  icon={<DeleteForeverIcon />}
                  variant="black"
                  handleClick={onDelete}
                />
              )}
            </div>
          </>
        )}
        {children && (
          <div className="children-wrapper">
            {cloneElement(children, {
              filterModalDisplayed: modalDisplayed,
            })}
          </div>
        )}
      </div>

      <FilterWidgetModal
        widget={widget}
        displayed={modalDisplayed}
        handleClose={() => setModalDisplayed(false)}
        handleSubmit={handleSubmitFilter}
      />
    </>
  )
}

WidgetWrapper.defaultProps = {
  children: null,
  readonly: false,
}

export default WidgetWrapper
