import { LAYER_NAMES } from 'services/map'
import { LayerProperties } from '../types'

const TrackLayerPaint: LayerProperties = {
  trackLayer: {
    id: LAYER_NAMES.trackLayer,
    type: 'line',
    paint: {
      'line-color': '#0088ce',
      'line-width': {
        stops: [
          [8, 1.3],
          [20, 3],
        ],
      },
    },
  },
  trackNameLayer: {
    type: 'symbol',
    id: `${LAYER_NAMES.trackLayer}-name`,
    minzoom: 7,
    layout: {
      'text-font': ['Open Sans Regular'],
      'text-size': 16,
      'symbol-placement': 'line-center',
      'text-allow-overlap': false,
      'text-anchor': 'top',
      'text-field': '{libelle}',
    },
    paint: {
      'text-color': '#838383',
    },
  },
  trackNumberLayer: {
    type: 'symbol',
    id: `${LAYER_NAMES.trackLayer}-number`,
    minzoom: 8,
    layout: {
      'text-font': ['Open Sans Regular'],
      'text-size': 16,
      'symbol-placement': 'line',
      'text-allow-overlap': false,
      'text-anchor': 'top',
      'text-field': '{code_ligne}',
    },
    paint: {
      'text-color': '#838383',
    },
  },
}

export default TrackLayerPaint
