import { ReactElement } from 'react'
import ConstructionPage from 'assets/pictures/page-en-construction.png'

import './SignalBox.scss'

const SignalBox = (): ReactElement => (
  <div className="signalBox">
    <div>
      <img
        className="signalBox"
        src={ConstructionPage}
        alt="page en construction"
      />
    </div>
  </div>
)

export default SignalBox
