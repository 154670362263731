import { ReactElement } from 'react'
import './CardList.scss'

interface Props {
  children: ReactElement[];
}

function CardList({ children }: Props): ReactElement {
  return (
    <div className="card-list">
      {children}
    </div>
  )
}

export default CardList
