import { MutableRefObject } from 'react'
import { Feature } from 'geojson'
import { WebMercatorViewport, MapRef, MapRequest } from 'react-map-gl'

import { store } from 'reducers/store'
import station from 'assets/icons/station.png'
import stationSelected from 'assets/icons/stationSelected.png'
import stationEditing from 'assets/icons/stationEditing.png'
import MAIN_API from 'config/config'

export const MAP_LAYER_SOURCE = {
  track: 'cassini_v2_gaia_habillage_valeur_margin_100',
  pk: 'cassini_reperekilometrique',
  pr: 'cassini_v2_gaia_point_remarquable',
}

export const LAYER_NAMES = {
  bvLayer: 'bv-layer',
  prLayer: 'pr-layer',
  pkLayer: 'pk-layer',
  trackLayer: 'track-layer',
  regionLayer: 'region-layer',
  sectionLayer: 'section-layer',
}

export const LAYER_URL = `${MAIN_API.proxy}/chartis/v2/layer`

export const formatLayerUrl = (sourceLayer: string, mode = 'line_geo_centroid', params = ''): string => {
  const { geometry } = store.getState().map?.perimeters?.features?.at(0) || {}
  let layerUrl = `${LAYER_URL}/${sourceLayer}/mvt/${mode}/?${params}`
  if (geometry) {
    layerUrl += `&bpolygon=${JSON.stringify(geometry)}`
  }

  return layerUrl
}

/**
 * Generate a new bbox based on geometry points
 */
export const getBbox = (points: Feature[]): number[] => {
  const bounds: number[][] = points.reduce((prev, curr) => {
    const { type } = curr?.geometry
    if (type === 'LineString') {
      return [...prev, ...curr?.geometry?.coordinates]
    } if (type === 'MultiLineString') {
      return [...prev, ...curr?.geometry?.coordinates.flat()]
    }

    return prev
  }, [])

  const longs = bounds.map(bound => bound[0])
  const lats = bounds.map(bound => bound[1])

  const bbox = [
    Math.min(...longs), Math.min(...lats),
    Math.max(...longs), Math.max(...lats),
  ]

  return bbox
}

/**
 * Generate a new ViewPort instance based on bbox and map client width
 */
export const getViewport = (bbox: number[], width = 600) => {
  let vp = null

  try {
    vp = new WebMercatorViewport({ width, height: 600 })
      .fitBounds(
        [[bbox[0], bbox[1]], [bbox[2], bbox[3]]],
        {
          padding: {
            top: 0, bottom: 0, left: 0, right: 0,
          },
        },
      )
  } catch (e) {
    /* */
  }

  return vp
}

/**
 * Replace env in request URL and add token if needed
 *
 * @param url to transform
 * @param resourceType use to check if token is needed
 * @returns MapRequest
 */
export const transformRequest = (url: string, resourceType: string): MapRequest => {
  if (!MAIN_API.proxy.includes('.dev.')) {
    const newEnv = MAIN_API.proxy.includes('staging') ? 'staging.dgexsol.' : 'dgexsol.'
    url = url.replace('dev.dgexsol.', newEnv)
  }

  if ((resourceType === 'Source' || resourceType === 'Tile')) {
    return {
      url,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    }
  }

  return { url }
}

/**
 * Load assets images in given map to be used in layers
 * @param mapRef
 */
export const loadBvImages = (mapRef: MutableRefObject<MapRef | undefined> | undefined) => {
  const currentMap = mapRef?.current?.getMap()
  currentMap.loadImage(station, (error: Error, image: HTMLImageElement | ImageBitmap) => {
    if (!error) { currentMap.addImage('station', image) }
  })
  currentMap.loadImage(stationSelected, (error: Error, image: HTMLImageElement | ImageBitmap) => {
    if (!error) { currentMap.addImage('stationSelected', image) }
  })
  currentMap.loadImage(stationEditing, (error: Error, image: HTMLImageElement | ImageBitmap) => {
    if (!error) { currentMap.addImage('stationEditing', image) }
  })
}
