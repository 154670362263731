/* eslint-disable camelcase */
/* eslint-disable max-len */
import fullOsmTheme from 'assets/mapstyles/full.json'
import {
  Dispatch,
  MutableRefObject, ReactElement, Ref, SetStateAction, useEffect, useRef, useState,
} from 'react'
import ReactMapGL, { MapEvent, MapRef, ViewportProps } from 'react-map-gl'
import { RequestManager } from '@osrdata/app_core/dist/requests'
import terms from 'common/terms'
import {
  loadBvImages, transformRequest, LAYER_NAMES, MAP_LAYER_SOURCE,
} from 'services/map'
import { usePrevious } from 'utils/hooks'
import RegionLayer from 'components/Layers/RegionLayer/RegionLayer'
import TrackLayer from 'components/Layers/TrackLayer/TrackLayer'
import { Zone, ZoneType } from 'reducers/zones/types'
import { Widget } from 'reducers/boards/types'
import { getViewport } from 'services'
import Loader from 'components/Loader/Loader'
import { handleFormatLabel } from 'utils/timeFormats'
import PRPopup from './Popup/PRPopup'
import BvLayer from './Layer/BvLayer'
import SectionLineLayer from './Layer/SectionLineLayer'

import './Map.scss'

type Props = {
  widget: Widget
  zone: Zone
  filterModalDisplayed?: boolean
  requestManager?: RequestManager
  isPreview?: boolean
  isConfLoading?: boolean
  canceled?: boolean
  onMetricLoad?: Dispatch<SetStateAction<boolean>>
}

export type Metric = {
  meta: {
    [key: string]: string
  }

  data: {
    key: string[]
    value: { label: string, value: number }[]
  }[]
}

const MapGL = ({
  zone, widget, filterModalDisplayed, requestManager, isPreview, isConfLoading, canceled, onMetricLoad,
}: Props): ReactElement => {
  const rq = requestManager || new RequestManager()
  const mapRef: MutableRefObject<MapRef | undefined> | undefined = useRef()
  const mapDOMRef = useRef(null)
  const filterParams = (widget?.filterParams || []).concat(widget?.parameters)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>('')
  const [popupEvents, setPopupEvents] = useState<MapEvent[]>()
  const [metricsData, setMetricsData] = useState<Metric>({ meta: {}, data: undefined })
  const [viewport, setViewport] = useState<ViewportProps>({
    latitude: 45.71034133320295,
    longitude: 4.84436973430733,
    zoom: 9,
    bearing: 0,
    pitch: 0,
  })
  const previousParams = usePrevious(filterParams)

  const addPopupEvent = (e: MapEvent) => {
    const { id } = e.features?.[0]?.properties || {}
    if (popupEvents?.find(popup => popup.features?.[0]?.properties?.id === id)) {
      return
    }
    setPopupEvents([...(popupEvents || []), e])
  }

  const removePopupEvent = (id: string) => () => {
    setPopupEvents(popupEvents?.filter(popup => popup.features?.[0]?.properties?.id !== id))
  }

  const loadMetrics = async () => {
    setLoading(true)

    try {
      rq.abort()
      const response = await rq.get(
        `/usage_reseau/metrics/${widget?.metric_slug}/cartography`, filterParams.reduce(
          (cur, acc) => {
            cur[acc.slug] = Array.isArray(acc.value) ? acc.value.join(',') : acc.value
            return cur
          }, { zone_id: zone.id },
        ) as unknown as Metric,
      )
      setMetricsData(response)
      setError('')
    } catch (e) {
      if (e?.response?.data?.detail) {
        setError(e.response.data.detail)
      } else if (e?.message === 'canceled') {
        // Set error to empty string to avoid displaying error message when request is canceled
        setError(' ')
      } else {
        setError(terms.Widgets.errorLoadingData)
      }
    }

    setLoading(false)
  }

  useEffect(() => {
    onMetricLoad?.(loading)
  }, [loading])

  useEffect(() => {
    if (filterModalDisplayed) {
      setPopupEvents([])
    }
  }, [filterModalDisplayed])

  useEffect(() => {
    if (!widget || !zone || canceled) {
      return
    }

    if (!isPreview) {
      loadMetrics()
    } else if (JSON.stringify(previousParams) !== JSON.stringify(filterParams)) {
      setPopupEvents([])
      loadMetrics()
    }
  }, [widget, zone])

  useEffect(() => {
    const vp = getViewport(zone?.bbox, mapDOMRef?.current?.clientWidth)
    if (!vp) return
    setViewport({ ...viewport, ...vp, zoom: vp?.zoom > 20 ? 20 : vp?.zoom })
  }, [zone?.bbox, mapDOMRef?.current?.clientWidth])

  const onViewportChange = (newViewport: ViewportProps) => {
    setViewport(newViewport)
  }

  const onFeatureClick = (e: MapEvent) => {
    if (e.features?.[0]?.sourceLayer === MAP_LAYER_SOURCE.pr) {
      const ids = zone?.components?.flatMap(
        component => component.elements.map(element => element?.gaia_id),
      )

      if (ids.includes(e.features?.[0]?.properties?.id)) {
        const meta = metricsData?.data?.find(metric => metric.key.includes(e.features?.[0]?.properties?.id))
        const unit = metricsData?.meta?.unit || ''
        e.features[0].properties.metrics = {
          ...meta.value.reduce((acc, { label, value }) => ({ ...acc, [label]: handleFormatLabel(value, unit) }), {}),
        }
      }

      addPopupEvent(e)
    }
  }

  const onFeatureClickSection = (e: MapEvent) => {
    if (e.features?.[0]?.layer?.id === LAYER_NAMES.sectionLayer) {
      const { label, value, unit } = e.features?.[0]?.properties
      const parsedValue = JSON.parse(value) as { label: string, value: number, unit: string }[]
      e.features[0].properties.metrics = { ...parsedValue.reduce((acc, curr) => ({ ...acc, [curr.label]: handleFormatLabel(curr.value, unit) }), {}) }
      e.features[0].properties.libelle = label
      addPopupEvent(e)
      return
    }
    if (e.features?.[0]?.sourceLayer === MAP_LAYER_SOURCE.pr) {
      addPopupEvent(e)
    }
  }

  if (isConfLoading || loading) {
    return <div className="map" ref={mapDOMRef}><Loader /></div>
  }

  if (canceled) {
    return <div className="map" ref={mapDOMRef}><p>Chargement de la prévisualisation annulé</p></div>
  }

  if (error) {
    return <div className="map" ref={mapDOMRef}><p>{error}</p></div>
  }

  return (
    <div className="map" ref={mapDOMRef}>
      <ReactMapGL
        {...viewport}
        preserveDrawingBuffer
        ref={mapRef as Ref<MapRef>}
        width="100%"
        height="100%"
        minZoom={5}
        transformRequest={transformRequest}
        mapStyle={fullOsmTheme}
        onLoad={() => { loadBvImages(mapRef) }}
        onViewportChange={onViewportChange}
        onClick={zone?.kind === ZoneType.SECTIONS ? onFeatureClickSection : onFeatureClick}
        interactiveLayerIds={(zone?.kind === ZoneType.SECTIONS
          ? [LAYER_NAMES.sectionLayer, LAYER_NAMES.bvLayer, `${LAYER_NAMES.bvLayer}-highlighted`, `${LAYER_NAMES.bvLayer}-name`]
          : [LAYER_NAMES.bvLayer, `${LAYER_NAMES.bvLayer}-highlighted`, `${LAYER_NAMES.bvLayer}-name`]
        )}
      >
        <RegionLayer />

        <TrackLayer />

        {zone?.kind === ZoneType.SECTIONS && <SectionLineLayer zone={zone} metric={metricsData} />}

        <BvLayer zone={zone} />

        {popupEvents?.map(event => {
          const {
            libelle, code_ci, code_ch, id, metrics, type,
          } = event?.features?.[0]?.properties || {}

          return (
            <PRPopup
              key={id}
              coordinate={event.lngLat}
              onClose={removePopupEvent(id)}
              title={libelle}
              sections={[
                { ...metrics },
                (id && type !== 'SECTION') ? {
                  '<i>Caractéristiques de la gare</i> : ': '',
                  'Code CI': code_ci,
                  'Code CH': code_ch,
                  'ID Gaia': id,
                } : {},
              ]}
            />
          )
        })}
      </ReactMapGL>
    </div>
  )
}

MapGL.defaultProps = {
  filterModalDisplayed: false,
  isPreview: false,
  isConfLoading: false,
  canceled: false,
  requestManager: undefined,
  onMetricLoad: () => { /* TO IMPLEMENT */ },
}

export default MapGL
