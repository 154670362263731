/* eslint-disable @typescript-eslint/no-explicit-any */
import { get } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'

export default createAsyncThunk(
  'zones/getUserPerimeters',
  async (_, thunkApi) => {
    try {
      const response = await get('/usage_reseau/user_perimeters/')
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
