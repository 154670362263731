import terms from 'common/terms'

export interface NavTabElement {
  icon?: string,
  title: string,
  path: string,
  role: string[],
}

const navTabElements: NavTabElement[] = [
  {
    title: terms.Routes.zones.tabTitle,
    path: terms.Routes.zones.path,
    role: [],
  },
  {
    title: terms.Routes.signalBox.title,
    path: terms.Routes.signalBox.path,
    role: [],
  },
]

export default navTabElements
