import { LAYER_NAMES } from 'services/map'
import { LayerProperties } from '../types'

const SectionLinePaint: LayerProperties = {
  track: {
    id: LAYER_NAMES.sectionLayer,
    type: 'line',
    paint: {
      'line-color': '#AA45FA',
      'line-width': {
        stops: [
          [8, 4],
          [20, 6],
        ],
      },
    },
  },
}

export default SectionLinePaint
