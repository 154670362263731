import terms from 'common/terms'
import Zone from 'pages/zone/Zone'
import Zones from 'pages/zones/ZonePage'
import UpdateZone from 'pages/updateZone/UpdateZone'
import Dashboards from 'pages/dashboards/Dashboards'
import { ReactElement } from 'react'
import SignalBox from 'pages/signalBox/SignalBox'

export type NavRoute = {
  title: string
  path: string
  component: () => ReactElement
}

const navRoutes: NavRoute[] = [
  {
    title: terms.Routes.zones.title,
    path: terms.Routes.zones.path,
    component: Zones,
  },
  {
    title: terms.Routes.zone.title,
    path: terms.Routes.zone.path,
    component: Zone,
  },
  {
    title: terms.Routes.dashboards.title,
    path: terms.Routes.dashboards.path,
    component: Dashboards,
  },
  {
    title: terms.Routes.updateZone.title,
    path: terms.Routes.updateZone.path,
    component: UpdateZone,
  },
  {
    title: terms.Routes.signalBox.title,
    path: terms.Routes.signalBox.path,
    component: SignalBox,
  },
]

export default navRoutes
