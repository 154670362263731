/* eslint-disable camelcase */
import { useEffect, useRef, useState } from 'react'
import { MapEvent } from 'react-map-gl'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Feature, Geometry } from 'geojson'
import { v4 as uuidv4 } from 'uuid'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import terms from 'common/terms'
import history from 'utils/history'
import {
  getBbox, getFlatZoneElements, getPrById, getSectionLabelValue, getTracks, getZonePrIds,
} from 'services'
import ZoneSelectors from 'reducers/zones/zones.selectors'
import { getZone, patchZones } from 'reducers/zones/zones.thunks'
import {
  Point, Section, Zone, ZoneType,
} from 'reducers/zones/types'
import { cloneDeep, isEqual } from 'lodash'
import { FormControlLabel, Switch } from '@mui/material'
import { setSnackbar } from 'reducers/app/app.reducers'
import { SnackbarSeverity } from 'reducers/app/types'
import MapGL, { MapHandle, MapSelect } from 'components/Map/DashboardMap'
import SimpleButton, { ButtonStyle } from 'components/SimpleButton/SimpleButton'
import Loader from 'components/Loader/Loader'
import { LAYER_NAMES } from 'services/map'
import { WrapperHandle } from './types'
import PointWrapper from './TypePoint'
import SectionWrapper from './TypeSection'
import './style.scss'

export const UpdateZone = () => {
  const dispatch = useDispatch()
  const wrapperRef = useRef<WrapperHandle>()
  const mapRef = useRef<MapHandle>()
  const { id } = useParams() as { id: string }
  const zone = useSelector(ZoneSelectors.getZoneById(id))
  const [draftZone, setDraftZone] = useState<Zone>(cloneDeep(zone))
  const [isLoading, setIsLoading] = useState(false)
  const [tracksParams, setTracksParams] = useState<{[key: number]: string[]}>({})

  useEffect(() => {
    dispatch(getZone(id))
  }, [])

  useEffect(() => {
    setDraftZone(cloneDeep(zone))
  }, [zone])

  const handleClose = () => {
    history.push('/')
  }

  // TODO : Touchy function, should be clean and refactored a bit
  // Implement a better way to handle draft zone, select logic and id generation
  const handleChange = (type: ZoneType) => async (section: Section[]) => {
    const sectionCopy: Section[] = cloneDeep(section)
    const tracks: Feature[] = []

    await Promise.all(sectionCopy?.map(async (component, i) => {
      const prIds = getZonePrIds(component.elements)

      if (draftZone?.tracks?.[i] && isEqual(tracksParams?.[i], prIds)) {
        tracks[i] = draftZone?.tracks[i]
        const selectedElement = component.elements.find(el => el.selected)
        component.elements = draftZone?.components?.[i]?.elements?.map(element => {
          element.selected = false
          return (selectedElement?.gaia_id === element.gaia_id && !element.intermediate) ? selectedElement : element
        })

        return
      }

      setTracksParams(prev => ({ ...prev, [i]: prIds }))

      if (type === ZoneType.SECTIONS && prIds.length > 1) {
        const { geometry, steps } = await getTracks(prIds)
        tracks[i] = { type: 'Feature', properties: {}, geometry }

        component.elements = steps
          .map(step => ({ ...step, id: uuidv4(), label: step?.libelle }))
          .reduce((prev, curr) => (curr?.gaia_id === prev[prev.length - 1]?.gaia_id ? prev : [...prev, curr]), [])
      }
    }))

    setDraftZone({
      ...zone, ...draftZone, tracks, components: sectionCopy,
    })
  }

  // Set new point data with the clicked map feature
  const handleMapSelect = async (e: MapEvent) => {
    const point: Point = e.features
      .filter((feat: MapSelect) => [
        LAYER_NAMES.bvLayer,
        `${LAYER_NAMES.bvLayer}-name`,
        LAYER_NAMES.prLayer,
        `${LAYER_NAMES.prLayer}-name`,
      ].includes(feat.layer.id))
      .map(({ properties }: MapSelect) => ({
        label: properties.libelle,
        gaia_id: properties.id,
        code_CI: properties.code_ci,
        code_CH: properties.code_ch,
      }))[0]

    if (!point) return

    if (!['BV', '00'].includes(point.code_CH)) {
      dispatch(setSnackbar({
        message: terms.UpdateZone.map.wrongSelect,
        severity: SnackbarSeverity.ERROR,
      }))
      return
    }

    wrapperRef.current.handleSelect({ ...point, selected: true })
  }

  const handlePatch = async () => {
    setIsLoading(true)

    const elements = getFlatZoneElements(draftZone)

    // Handle error display and stop patching
    if (elements.find(point => !point.label || !point.gaia_id)) {
      wrapperRef.current.handleErrors(true)
      setIsLoading(false)
      return
    }

    let geometry: Geometry = null

    if (elements.length < 2) {
      const feature = await getPrById(elements[0].gaia_id)
      const coordinate = feature?.geometry?.coordinates
      geometry = { type: 'LineString', coordinates: [coordinate, coordinate] }
    } else {
      geometry = (await getTracks(getZonePrIds(elements))).geometry
    }

    const tracks: Feature[] = [{ type: 'Feature', properties: {}, geometry }]
    const bbox = getBbox(tracks)
    setDraftZone({ ...zone, ...draftZone, bbox })

    // Setting a new draft bbox will trigger viewport update
    // This promise is here to wait for map animation end before printing the image
    await new Promise(_ => setTimeout(_, 1000))

    mapRef.current?.handlePrintMap(async (fd: FormData) => {
      dispatch(patchZones({
        zoneId: zone.id,
        components: draftZone?.components?.map(s => ({
          label: getSectionLabelValue(s),
          elements: s.elements.map(({
            code_CH, code_CI, gaia_id, label, intermediate,
          }) => ({
            code_CH, code_CI, gaia_id, label, intermediate,
          })),
        })),
        bbox,
        thumbnail_file: fd,
      }))
      setIsLoading(false)
    })
  }

  const handleToggleBV = () => {
    mapRef.current?.handleToggleUnselectedBV()
  }

  return (
    <div id="updateZonePage">
      <div className="header">
        <div tabIndex={-1} role="button" onClick={history.goBack}>
          <ArrowBackIcon />
          <span>{terms.UpdateZone.header.backLabel}</span>
        </div>
        {isLoading
          ? <Loader />
          : (
            <SimpleButton
              style={ButtonStyle.white}
              title={terms.UpdateZone.header.buttonLabel}
              onClick={handlePatch}
            />
          )}
        <FormControlLabel
          onClick={e => e.stopPropagation()}
          className="toggle-bv"
          label={terms.UpdateZone.header.toggleBV}
          control={<Switch defaultChecked onChange={handleToggleBV} />}
        />
        <CloseIcon
          titleAccess={terms.UpdateZone.header.closeHover}
          onClick={handleClose}
          className="close-icon"
        />
      </div>
      <div className="content">
        <div className="panel">
          {/* eslint-disable-next-line no-nested-ternary */}
          { !zone?.kind ? null : zone?.kind === ZoneType.PRSET
            ? <PointWrapper ref={wrapperRef} zone={zone} onChange={handleChange(ZoneType.PRSET)} />
            : <SectionWrapper ref={wrapperRef} zone={zone} onChange={handleChange(ZoneType.SECTIONS)} />}
        </div>
        <div className="map">
          <MapGL
            ref={mapRef}
            zone={draftZone || zone}
            onSelect={handleMapSelect}
            hideUpdateButton
          />
        </div>
      </div>
    </div>
  )
}

export default UpdateZone
