import { LAYER_NAMES } from 'services/map'
import { LayerProperties } from 'components/Layers/types'

const SectionLinePaint: LayerProperties = {
  track: {
    id: LAYER_NAMES.sectionLayer,
    type: 'line',
    minzoom: 7,
    paint: {
      'line-color': '#AA45FA',
      'line-width': 6,
    },
  },
}

export default SectionLinePaint
