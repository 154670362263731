/* eslint-disable max-len */
import { LAYER_NAMES } from 'services/map'
import { LayerProperties } from 'components/Layers/types'
import { Expression } from 'mapbox-gl'

const getBvNoPostLabel: mapboxgl.Expression = ['all', ['any', ['==', ['get', 'code_ch'], 'BV'],
  ['==', ['get', 'code_ch'], '00']], ['!', ['in', 'Poste', ['get', 'libelle']]]]

const matchSelection = (
  selection: string[] = [],
  ok: string | number | Expression,
  nok: string | number | Expression,
): Expression => [
  'match', ['get', 'id'], selection, ok, nok,
]

const RpLayerPaint = (
  ids: string[] = [],
): LayerProperties => ({
  bvLayer: {
    id: LAYER_NAMES.bvLayer,
    minzoom: 9,
    type: 'circle',
    paint: {
      'circle-color': 'white',
      'circle-radius': 4,
      'circle-stroke-color': '#0088ce',
      'circle-stroke-width': 2,
    },
    filter: getBvNoPostLabel,
  },
  bvLayerHighlighted: {
    id: `${LAYER_NAMES.bvLayer}-highlighted`,
    type: 'symbol',
    layout: {
      'icon-image': 'stationSelected',
      'icon-size': 0.55,
      'icon-allow-overlap': true,
      'icon-offset': [0, 2.6],
    },
    paint: {},
    filter: ['in', 'id', ...ids],
  },
  bvLayerName: {
    id: `${LAYER_NAMES.bvLayer}-name`,
    minzoom: 9,
    type: 'symbol',
    layout: {
      'text-font': matchSelection(ids, ['literal', ['Open Sans Bold']], ['literal', ['Open Sans Regular']]),
      'text-size': 14,
      'text-anchor': 'left',
      'symbol-placement': 'point',
      'text-allow-overlap': false,
      'text-offset': [1.5, 0],
      'text-field': '{libelle}',
    },
    paint: {
      'text-color': '#0088ce',
      'text-halo-color': 'hsl(0, 0%, 100%)',
      'text-halo-width': matchSelection(ids, 1, 0.1),
    },
    filter: getBvNoPostLabel,
  },
})

export default RpLayerPaint
