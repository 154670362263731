import { ReactElement, useState } from 'react'
import { Alert, Snackbar } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { SnackbarSeverity } from 'reducers/app/types'

interface CustomSnackbarProps {
  message: string,
  displaySnackbar: boolean,
  handleClose: () => void,
  severity?: SnackbarSeverity
}

const CustomSnackbar = ({
  message, displaySnackbar, handleClose, severity,
}: CustomSnackbarProps): ReactElement => {
  const [displayCloseIcon, setDisplayCloseIcon] = useState(false)

  const toggleOnCloseIcon = () => {
    setDisplayCloseIcon(true)
  }

  const toggleOffCloseIcon = () => {
    setDisplayCloseIcon(false)
  }

  const handleCloseAction = () => {
    handleClose()
    setDisplayCloseIcon(false)
  }
  return (
    <Snackbar
      open={displaySnackbar}
      onClose={handleCloseAction}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      disableWindowBlurListener
      onMouseEnter={toggleOnCloseIcon}
      onMouseLeave={toggleOffCloseIcon}
    >
      <Alert
        action={displayCloseIcon && (
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleCloseAction}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )}
        severity={severity}
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

CustomSnackbar.defaultProps = {
  severity: 'success',
}

export default CustomSnackbar
