import { ReactElement } from 'react'

import './CustomButton.scss'

interface Props {
  title: string;
  handleClick: () => void;
  icon?: ReactElement;
  variant?: 'primary' | 'secondary' | 'filter' | 'black' | 'borderless';
  className?: string;
  disabled?: boolean;
}

const CustomButton = ({
  title, handleClick, icon, variant, className, disabled,
}: Props): ReactElement => (
  <button
    disabled={disabled}
    type="button"
    className={`customButton ${variant && variant} ${className}`}
    onClick={handleClick}
  >
    {icon && (
      <div className="zone-card-button-icon">
        {icon}
      </div>
    )}
    <span>{title}</span>
  </button>
)

CustomButton.defaultProps = {
  icon: undefined,
  variant: undefined,
  className: '',
  disabled: false,
}

export default CustomButton
