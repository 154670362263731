/* eslint-disable dot-notation */
import {
  ReactElement, useCallback, useEffect, useState,
} from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import terms from 'common/terms'
import CardList from 'components/CardList/CardList'
import CardWrapper from 'components/CardWrapper/CardWrapper'
import FilterHeader from 'components/FilterHeader/FilterHeader'
import MapGL from 'components/Map/DashboardMap'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  handleDefaultClick, handleFavoriteClick, handleSortDirection, handleSortType,
} from 'common/functions'
import { RootState } from 'reducers/store'
import { getZone } from 'reducers/zones/zones.thunks'
import { Board, ParamsCreateBoard } from 'reducers/boards/types'
import { FilterSettings } from 'reducers/types'
import zonesSelectors from 'reducers/zones/zones.selectors'
import { setFilterSettings } from 'reducers/boards/boards.reducers'
import {
  copyBoard,
  deleteBoard, getBoards, patchBoard, postBoard,
} from 'reducers/boards/boards.thunk'
import Tab from 'components/DashboardTab/Tab'
import { useRole } from 'utils/hooks'
import DashBoardCard from './DashboardCard/DashboardCard'
import DashboardUpdateModal from './Modals/DashboardUpdateModal'
import DashboardCreateModal from './Modals/DashboardCreateModal'
import DashboardDeleteModal from './Modals/DashboardDeleteModal'

import './zone.scss'

const Zone = (): ReactElement => {
  const dispatch = useDispatch()
  const { id } = useParams() as { id: string }
  const zone = useSelector(zonesSelectors.getZoneById(id))
  const { isOwner, canModify } = useRole(zone)
  const { filterSettings, list, loadingList } = useSelector((state: RootState) => state.boards)
  const [selectedBoard, setSelectedBoard] = useState<Board | null>(null)
  const openedBoards = useSelector((state: RootState) => state.boards.opened)
  const [displayModal, setDisplayModal] = useState<'add' | 'patch' | 'delete' | 'share'>(null)
  const debouncedSearch = useCallback(debounce(
    (newSettings: FilterSettings) => dispatch(getBoards({ zoneId: id, settings: newSettings })), 250,
  ), [])

  useEffect(() => {
    dispatch(getZone(id))
    dispatch(getBoards({ zoneId: id, settings: filterSettings }))
  }, [])

  // Filter handlers
  const handleFilterChange = (newSettings: FilterSettings) => {
    dispatch(setFilterSettings(newSettings))
    dispatch(getBoards({ zoneId: id, settings: newSettings }))
  }
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSettings = { ...filterSettings, search: event.target.value }
    dispatch(setFilterSettings(newSettings))
    debouncedSearch(newSettings)
  }

  const handleFavorite = (board: Board) => () => {
    dispatch(patchBoard({ id: board.id, favorite: !board.favorite }))
  }
  const handleCopy = (board: Board) => () => {
    dispatch(copyBoard(board.id))
  }
  const handleModify = (board: Board) => () => {
    setSelectedBoard(board)
    setDisplayModal('patch')
  }
  const handleDelete = (board: Board) => () => {
    setSelectedBoard(board)
    setDisplayModal('delete')
  }

  // Modal handlers
  const handleCloseModal = () => setDisplayModal(null)
  const handleModifyBoard = (newBoard: string, newDescription: string) => {
    dispatch(patchBoard({ id: selectedBoard?.id || '', name: newBoard, description: newDescription }))
  }

  const handleCreateBoard = (params: ParamsCreateBoard) => {
    dispatch(postBoard(params))
  }

  const handleDeleteBoard = () => {
    if (selectedBoard) {
      dispatch(deleteBoard(selectedBoard.id))
    }
  }

  return (
    <>
      <div id="zone" className={`${openedBoards[id] && openedBoards[id].length > 0 ? 'tab-displayed' : ''}`}>
        <Tab isHome zoneId={id} />
        <section className="dashboard-list">
          <div className="dashboard-list-header">
            {!isOwner && (
            <p className="shared">
              {`${terms.Zones.Card.sharedInfo} ${canModify ? ' en écriture' : ' en lecture'}`}
            </p>
            )}
            <div className="dashboard-list-header-title">
              <h2>{zone?.name}</h2>
              <p className="description">
                {zone?.description}
              </p>
            </div>
            <Link
              className="dashboard-list-header-button"
              to={terms.Routes.zones.path}
            >
              <ArrowBackIcon fontSize="small" />
              <span>
                {terms.Common.return}
              </span>
            </Link>
            {!isOwner && (
            <p className="shared">
              {`${terms.Zones.Card.sharedInfo} ${canModify ? ' en écriture' : ' en lecture'}`}
            </p>
            )}
          </div>
          <div className="dashboard-list-body">
            <CardList>
              <FilterHeader
                handleDefaultClick={() => handleDefaultClick(handleFilterChange, filterSettings)}
                handleFavoriteClick={() => handleFavoriteClick(handleFilterChange, filterSettings)}
                allElementsLabel={terms.Dashboard.labelAllBoard}
                allElementsCount={loadingList === 'succeeded' ? list.length : 0}
                handleSearchChange={handleSearchChange}
                filterSettings={filterSettings}
                handleChangeSortType={() => handleSortType(handleFilterChange, filterSettings)}
                handleChangeSortDirection={() => handleSortDirection(handleFilterChange, filterSettings)}
                buttonLabel={terms.Dashboard.labelAddBoard}
                handleAddClick={() => setDisplayModal('add')}
                isLoading={loadingList === 'pending'}
                variant="secondary"
                disableAction={!canModify}
              />
              <>
                {loadingList === 'succeeded' && list.map(board => (
                  <CardWrapper key={board.id}>
                    <div className="dashboard-list-body-card">
                      <DashBoardCard
                        board={board as Board}
                        zoneId={id}
                        handleClickFavorite={handleFavorite(board)}
                        handleClickCopy={handleCopy(board)}
                        handleClickModify={handleModify(board)}
                        handleClickDelete={handleDelete(board)}
                      />
                    </div>
                  </CardWrapper>
                ))}
              </>
            </CardList>
          </div>

        </section>
        <section className="dashboard-map">
          <MapGL zone={zone} readOnly={!canModify} />
        </section>
      </div>
      <DashboardCreateModal
        onModal={displayModal === 'add'}
        handleClose={handleCloseModal}
        handleSubmit={handleCreateBoard}
        zoneId={id}
      />
      <DashboardUpdateModal
        onModal={displayModal === 'patch'}
        handleClose={handleCloseModal}
        selectedBoard={selectedBoard}
        handleModify={handleModifyBoard}
      />
      <DashboardDeleteModal
        onModal={displayModal === 'delete'}
        handleClose={handleCloseModal}
        selectedBoard={selectedBoard}
        handleDelete={handleDeleteBoard}
      />
    </>
  )
}

export default Zone
