/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  get, patch, post, deleteRequest,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import { FilterSettings, FilterSortDirection } from 'reducers/types'
import { SnackbarSeverity } from 'reducers/app/types'
import { setSnackbar } from 'reducers/app/app.reducers'
import terms from 'common/terms'
import { Feature } from 'geojson'
import { getSectionLabelValue, getTracks, getZonePrIds } from 'services'
import {
  ParamsCreateZone, ParamsModifyZone, Zone, ZoneType,
} from './types'

const getZones = createAsyncThunk(
  'zones/getZones',
  async (settings: FilterSettings, thunkApi) => {
    try {
      const response = await get('/usage_reseau/zones/', {
        ...(settings.defaults && { filter: 'default' }),
        ...(settings.favorites && { filter: 'favorite' }),
        ...(settings.search && { search: settings.search }),
        ordering: `${settings.sortDirection === FilterSortDirection.DESC ? '-' : ''}${settings.sortBy}`,
      })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getZone = createAsyncThunk(
  'zones/getZone',
  async (id: string, thunkApi) => {
    try {
      const zone = await get(`/usage_reseau/zones/${id}`) as Zone
      const tracks: Feature[] = []

      if (zone?.kind === ZoneType.SECTIONS) {
        await Promise.all(zone?.components?.map(async (component, i) => {
          component.id = component.id || uuidv4()
          const { geometry, steps } = await getTracks(getZonePrIds(component.elements))
          tracks[i] = { type: 'Feature', properties: {}, geometry }

          component.label = getSectionLabelValue(component)

          component.elements = steps
            .map(step => ({ ...step, id: uuidv4(), label: step?.libelle }))
            .reduce((prev, curr) => (curr?.gaia_id === prev[prev.length - 1]?.gaia_id ? prev : [...prev, curr]), [])
        }))
      }

      return { ...zone, tracks }
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postZones = createAsyncThunk(
  'zones/postZones',
  async (params: ParamsCreateZone, thunkApi) => {
    try {
      const response = await post('/usage_reseau/zones/', params)
      thunkApi.dispatch(setSnackbar({
        message: terms.Zones.Snackbar.createSuccess,
        severity: SnackbarSeverity.SUCCESS,
      }))
      return response
    } catch (e: any) {
      thunkApi.dispatch(setSnackbar({
        message: terms.Zones.Snackbar.createError,
        severity: SnackbarSeverity.ERROR,
      }))
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const patchZones = createAsyncThunk(
  'zones/patchZones',
  async (params: ParamsModifyZone, thunkApi) => {
    try {
      const { zoneId, thumbnail_file, ...parameters } = params
      const response = await patch(`/usage_reseau/zones/${zoneId}/`, parameters)
      if (thumbnail_file) await patch(`/usage_reseau/zones/${zoneId}/thumbnail/`, thumbnail_file)

      thunkApi.dispatch(setSnackbar({
        message: terms.Zones.Snackbar.modifySuccess,
        severity: SnackbarSeverity.SUCCESS,
      }))

      return response
    } catch (e: any) {
      thunkApi.dispatch(setSnackbar({
        message: terms.Zones.Snackbar.modifyError,
        severity: SnackbarSeverity.ERROR,
      }))
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const copyZone = createAsyncThunk(
  'zones/copyZone',
  async (id: string, thunkApi) => {
    try {
      const response = await post(`/usage_reseau/zones/${id}/copy/`, {
        zone_id: id,
      })
      thunkApi.dispatch(setSnackbar({
        message: terms.Zones.Snackbar.duplicateSuccess,
        severity: SnackbarSeverity.SUCCESS,
      }))
      return response
    } catch (e: any) {
      thunkApi.dispatch(setSnackbar({
        message: terms.Zones.Snackbar.duplicateError,
        severity: SnackbarSeverity.ERROR,
      }))
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteZone = createAsyncThunk(
  'zones/deleteZone',
  async (id: string, thunkApi) => {
    try {
      const response = await deleteRequest(`/usage_reseau/zones/${id}/`)
      thunkApi.dispatch(setSnackbar({
        message: terms.Zones.Snackbar.deleteSuccess,
        severity: SnackbarSeverity.SUCCESS,
      }))
      return response
    } catch (e: any) {
      thunkApi.dispatch(setSnackbar({
        message: terms.Zones.Snackbar.deleteError,
        severity: SnackbarSeverity.ERROR,
      }))
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  getZones,
  getZone,
  postZones,
  patchZones,
  deleteZone,
  copyZone,
}
