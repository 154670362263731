import { ReactElement, useEffect, useState } from 'react'
import { cloneDeep } from 'lodash'
import { post } from '@osrdata/app_core/dist/requests'
import { FormGroup, FormLabel, OutlinedInput } from '@mui/material'
import getWidgetParamsValue from 'services/widget'
import { ConfParams, Widget } from 'reducers/boards/types'
import CustomButtonCheck from 'components/CustomButtonCheck/CustomButtonCheck'
import CustomMultitimeRange from 'components/CustomMultitimeRange/CustomMultitimeRange'
import CustomMultiRange from 'components/CustomMultiRange/CustomMultiRange'
import CustomButton from 'components/CustomButton/CustomButton'
import CustomSelectCheck from 'components/CustomSelectCheck/CustomSelectCheck'
import CustomMultiText from 'components/CustomMultiText/CustomMultiText'
import CustomModal from 'components/CustomModal/CustomModal'
import terms from 'common/terms'

import './modalsWidget.scss'

interface Props {
  widget: Widget
  displayed: boolean
  handleClose: () => void
  handleSubmit: (params: { slug: string, value: string }[]) => void
}

const FilterWidgetModal = ({
  widget, displayed, handleClose, handleSubmit,
}: Props): ReactElement => {
  const [formInputs, setFormInputs] = useState<ConfParams[]>([])
  const [params, setParams] = useState({})
  const getTypeForSlug = (slug: string) => formInputs.find(param => param.slug === slug)?.type

  useEffect(() => {
    post(
      `/usage_reseau/metric-parameters/${widget.metric_slug}/${widget.format_slug}`, {},
    ).then(
      (newParams: ConfParams[]) => setFormInputs(newParams.filter(param => !param.required)),
    )
  }, [])

  useEffect(() => {
    if (!displayed) setParams({})
    else {
      setParams(widget?.filterParams?.reduce((acc, param) => {
        const type = getTypeForSlug(param.slug)
        if (
          type === 'multi_choice_enum'
          || param.slug === 'multi_time_range'
          || param.slug === 'circulation_number_range'
          || param.slug === 'circulation_number'
        ) {
          acc[param.slug] = param.value.split(',')
        } else {
          acc[param.slug] = param.value
        }

        return acc
      }, {}) || {})
    }
  }, [displayed])

  /**
   * Update the params as a string for a given key
   *
   * @param slug key params to update
   * @returns onChange callback with the new string value as parameter
   */
  const handleChangeSingleParams = (slug: string) => (value: string) => {
    setParams({ ...params, [slug]: value })
  }

  /**
   * Update the params as an array of strings for a given key
   *
   * @param slug key params to update
   * @returns onChange callback with the new string | string[] value as parameter and an optional value to remove
   */
  const handleChangeMultipleParams = (slug: string) => (value: string | string[], valueToRemove?: string) => {
    const copy = cloneDeep(params)
    if (!copy[slug]) copy[slug] = []

    const deleteItem = (item: string) => {
      if (!item) return
      copy[slug] = copy[slug].filter((i: string) => i !== item)
    }

    const updateItems = (items: string[]) => {
      items.forEach(item => {
        if (copy[slug].includes(item)) {
          deleteItem(item)
        } else {
          copy[slug].push(item)
        }
      })
    }

    deleteItem(valueToRemove)

    if (Array.isArray(value)) {
      updateItems(value)
    } else {
      updateItems([value])
    }

    setParams(copy)
  }

  const handleSave = () => {
    handleSubmit(
      Object.entries(params).map(([slug, value]) => {
        if (Array.isArray(value)) {
          return ({ slug, value: value.join(',') } as { slug: string, value: string })
        }
        return ({ slug, value } as { slug: string, value: string })
      }) || [],
    )
    handleClose()
  }

  const handleReset = () => {
    setParams({})
  }

  const renderInput = ({ type, slug, values }: { type: string, slug: string, values: string[] }) => {
    const value = getWidgetParamsValue(widget?.parameters)(slug)

    switch (slug) {
      case 'multi_time_range':
        return (
          <CustomMultitimeRange
            selected={params[slug]}
            handleChange={handleChangeMultipleParams(slug)}
          />
        )
      case 'circulation_number':
        return (
          <CustomMultiText
            selected={params[slug]}
            handleChange={handleChangeMultipleParams(slug)}
          />
        )
      case 'circulation_number_range':
        return (
          <CustomMultiRange
            selected={params[slug]}
            handleChange={handleChangeMultipleParams(slug)}
          />
        )
      default:
        if (type === 'multi_choice_enum') {
          return values.length < 6 ? (
            <CustomButtonCheck
              selected={params[slug]}
              list={values}
              handleclick={handleChangeMultipleParams(slug)}
            />
          ) : (
            <CustomSelectCheck
              selected={params[slug]}
              list={values}
              handleclick={handleChangeMultipleParams(slug)}
            />
          )
        }
        return (
          <OutlinedInput
            value={value}
            onChange={({ target }) => handleChangeSingleParams(slug)(target.value)}
          />
        )
    }
  }

  return (
    <CustomModal
      open={displayed}
      handleClose={handleClose}
      title={terms.Widgets.filterModal.title}
      className="filterDashboardModal"
    >
      <div className="breakLine" />
      <div className="filter-widget">
        <>
          {formInputs?.map(input => (
            <FormGroup key={input.slug} className={`form-group ${input.type}`}>
              <FormLabel className="label">
                {input.name}
              </FormLabel>
              {renderInput(input)}
            </FormGroup>
          ))}
        </>
      </div>
      <div className="filter-widget-buttons">
        <button
          className="reset-button"
          type="button"
          onClick={handleReset}
        >
          {terms.Widgets.filterModal.buttons.reset}
        </button>
        <CustomButton
          title={terms.Widgets.filterModal.buttons.apply}
          handleClick={handleSave}
          variant="primary"
        />
      </div>
    </CustomModal>
  )
}

export default FilterWidgetModal
