import { ReactElement, ReactNode } from 'react'
import logoDGEX from 'assets/logos/logo-DGEXSOL-full-white.svg'
import logoPdi from 'assets/pictures/logo-PDI.png'
import AccountMenu from './AccountMenu'
import './TopBar.scss'

type Props = {
  innerComponent?: ReactNode;
  hasAccess: boolean;
}

const defaultProps = {
  innerComponent: <div />,
}

export default function TopBar({ innerComponent, hasAccess }: Props): ReactElement {
  return (
    <div className="topbar">
      <header role="banner" className="sncf-banner">
        <div className="mastheader-logo">
          <img src={logoDGEX} alt="dgex" />
          <img className="pdi" src={logoPdi} alt="PDI" />
        </div>
      </header>
      {hasAccess && innerComponent}
      <AccountMenu />
    </div>
  )
}

TopBar.defaultProps = defaultProps
