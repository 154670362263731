import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ViewportProps } from 'react-map-gl'
import { MapState } from './types'
import getUserPerimeters from './map.thunks'

const initialState: MapState = {
  viewport: {
    latitude: 45.71034133320295,
    longitude: 4.84436973430733,
    zoom: 9,
    bearing: 0,
    pitch: 0,
  },
  perimeters: null,
}

export const map = createSlice({
  name: 'map',
  initialState,
  reducers: {
    resetViewport: state => {
      state.viewport = initialState.viewport
    },
    updateViewport: (state, action: PayloadAction<ViewportProps>) => {
      state.viewport = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(getUserPerimeters.fulfilled, (state, action) => {
      state.perimeters = action.payload.results
    })
  },
})

export const {
  updateViewport,
  resetViewport,
} = map.actions

export default map.reducer
