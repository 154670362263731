import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'
import { Zone } from 'reducers/zones/types'
import { LAYER_NAMES } from 'services/map'
import SectionLinePaint from './SectionLinePaint'
import { Metric } from '../Map'

type Props = {
  zone: Zone
  metric: Metric
}

export default function SectionLineLayer({ zone, metric }: Props): ReactElement {
  if (!zone?.components || !zone?.components[0]) return null
  const { data, meta = {} } = metric
  const { unit = '' } = meta

  return (
    <Source
      id="test-new"
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features: zone?.tracks?.map((feature, i) => ({
          ...feature,
          properties: {
            id: data?.at(i)?.key.join('-'),
            label: zone.components[i].label,
            value: data?.at(i)?.value,
            type: 'SECTION',
            unit,
          },
        })) || [],
      }}
    >
      <Layer
        {...SectionLinePaint.track}
        beforeId={LAYER_NAMES.bvLayer}
      />
    </Source>
  )
}
