import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'
import { MAP_LAYER_SOURCE, formatLayerUrl } from 'services/map'
import PklayerPaint from './PkLayerPaint'

export default function PkLayer(): ReactElement {
  return (
    <Source
      type="vector"
      url={formatLayerUrl(MAP_LAYER_SOURCE.pk, 'line_geo')}
    >
      <Layer
        {...PklayerPaint.kpLayer}
        source-layer={MAP_LAYER_SOURCE.pk}
      />

      <Layer
        {...PklayerPaint.kpLibelleLayer}
        source-layer={MAP_LAYER_SOURCE.pk}
      />
    </Source>
  )
}
