import { Dispatch, SetStateAction } from 'react'
import { FilterSettings, FilterSortBy, FilterSortDirection } from 'reducers/types'
import { GroupFormatted } from 'reducers/zones/types'

type HandleDefaultClick = (handleChange: (newSettings: FilterSettings) => void, filter: FilterSettings) => void
const handleDefaultClick: HandleDefaultClick = (handleChange, filter) => {
  handleChange({ ...filter, defaults: !filter.defaults, favorites: false })
}

type HandleFavoriteClick = (handleChange: (newSettings: FilterSettings) => void, filter: FilterSettings) => void
const handleFavoriteClick: HandleFavoriteClick = (handleChange, filter) => {
  handleChange({ ...filter, favorites: !filter.favorites, defaults: false })
}

type HandleSortType = (handleChange: (newSettings: FilterSettings) => void, filter: FilterSettings) => void
const handleSortType: HandleSortType = (handleChange, filter) => {
  handleChange({
    ...filter,
    sortBy: filter.sortBy === FilterSortBy.NAME ? FilterSortBy.LAST_MODIFIED : FilterSortBy.NAME,
  })
}

type HandleSortDirection = (handleChange: (newSettings: FilterSettings) => void, filter: FilterSettings) => void
const handleSortDirection: HandleSortDirection = (handleChange, filter) => {
  handleChange({
    ...filter,
    sortDirection: filter.sortDirection === FilterSortDirection.ASC
      ? FilterSortDirection.DESC : FilterSortDirection.ASC,
  })
}

type HandleChangeShareGroup = (
  event: React.ChangeEvent<{ value: string }>,
  id: string,
  type: string,
  goupList: GroupFormatted[],
  setGroupList: Dispatch<SetStateAction<GroupFormatted[]>>
) => void
const handleChangeShareGroup: HandleChangeShareGroup = (event, id, type, groupList, setGroupList) => {
  if (type === 'check') {
    const newGroup = groupList.map(group => {
      if (group.group === id) {
        if (!group.check === false) {
          return {
            ...group,
            check: !group.check,
            permission: null,
          }
        }
        return {
          ...group,
          check: !group.check,
        }
      }
      return group
    })
    setGroupList(newGroup)
  }
  if (type === 'select') {
    const newGroup = groupList.map(group => {
      if (group.group === id) {
        return {
          ...group,
          permission: event.target.value,
        }
      }
      return group
    })
    setGroupList(newGroup)
  }
}

export {
  handleDefaultClick,
  handleFavoriteClick,
  handleSortType,
  handleSortDirection,
  handleChangeShareGroup,
}
