import { useEffect, useState } from 'react'
import { usePrevious } from 'utils/hooks'

import './CustomInputText.scss'

type Props = {
  defaultValue: string
  type?: 'string' | 'number'
  onChange: (value: string) => void
}

const CustomInputText = ({
  defaultValue, type, onChange,
}: Props) => {
  const [value, setValue] = useState(defaultValue)
  const previousDefault = usePrevious(defaultValue)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = type === 'number'
      ? event.target.value.replace(/\D/g, '')
      : event.target.value

    setValue(inputValue)
    onChange(inputValue)
  }

  useEffect(() => {
    if (!previousDefault) {
      setValue(defaultValue)
    }
  }, [defaultValue, previousDefault])

  return (
    <div className="input-text">
      <input
        className="input"
        type="text"
        value={value}
        onChange={handleChange}
      />
    </div>
  )
}

CustomInputText.defaultProps = {
  type: 'string',
}

export default CustomInputText
