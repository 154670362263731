import {
  MutableRefObject, useEffect, useRef, useState,
} from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers/store'
import { Zone } from 'reducers/zones/types'

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export function useRole(zone: Zone) {
  const userId = useSelector((state: RootState) => state?.user?.account.id)
  const isOwner = !!userId && zone?.owner === userId
  const canModify = isOwner || zone?.can_modify

  return { isOwner, canModify }
}

export function useIsVisible(ref: MutableRefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [ref])

  return isIntersecting
}
