import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'
import { Zone } from 'reducers/zones/types'
import { MAP_LAYER_SOURCE, formatLayerUrl } from 'services/map'
import BvLayerPaint from './BvLayerPaint'

type Props = {
  zone?: Zone
}

export function BvLayer({ zone }: Props): ReactElement {
  const mode = 'full_rgi_line_geo_centroid'
  const params = 'habillage_statut_rgi_line_geo__overlaps=Exploitée,Transférée en voie de service'
  const points = zone?.components?.flatMap(section => section?.elements) || []
  const ids = points.filter(point => !!point.gaia_id).map(point => point.gaia_id) || []
  const paints = BvLayerPaint([...new Set(ids)])

  if (ids.length === 0) {
    return null
  }

  return (
    <Source
      id="bv-source"
      type="vector"
      url={formatLayerUrl(MAP_LAYER_SOURCE.pr, mode, params)}
    >

      <Layer
        {...paints.bvLayer}
        source-layer={MAP_LAYER_SOURCE.pr}
      />

      <Layer
        {...paints.bvLayerName}
        source-layer={MAP_LAYER_SOURCE.pr}
      />

      {ids.length > 0 && (
        <Layer
          {...paints.bvLayerHighlighted}
          source-layer={MAP_LAYER_SOURCE.pr}
        />
      )}

    </Source>
  )
}

BvLayer.defaultProps = {
  zone: [],
}

export default BvLayer
